import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { API_URL,IMG_URL, SEARCH_COMPANIES, ADD_REVIEW, MTAG_TITLE_REVIEWS, MTAG_DESCRIPTION_REVIEWS, MTAG_KEYWORDS_REVIEWS, MTAG_URL_REVIEWS, TITLE_REVIEWS } from "../config/Config";
import axios from "axios";
import { getLocalData } from "../config/localStrorage";
import { useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
const rowsPerPage = 7; // Number of rows to display per page

function Review() {
  const local = getLocalData();
  const { id } = useParams();
  const navigate = useNavigate();
  const [reviewsData, setReviewsData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const fetchVendorData = async () => {
    var productUrl = API_URL + SEARCH_COMPANIES;
    const formData = new FormData();
    formData.append("id", id);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setCompanyData(response.data.data[0]);
      setReviewsData(response.data.reviews);
    });
  };
  useEffect(() => {
    fetchVendorData();
  }, []);

  const changeRating = (newRating) => {
    setRating(newRating);
  };
  function leaveReview() {
    var productUrl = API_URL + ADD_REVIEW;
    const formData = new FormData();

    if (local.userId <= 0) {
      toast.error("Please login first");
      return;
    }

    if (rating === 0) {
      toast.error("Please select ratings");
      return;
    }
    if (comment === "") {
      toast.error("Please write comments");
      return;
    }
    formData.append("reviewUserId", local.userId);
    formData.append("cdId", id);
    formData.append("custdId", local.customerId);
    formData.append("comment", comment);
    formData.append("rating", rating);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      const msg = response.data.msg;
      if (response.data.status === true) {
        toast.success(msg);
        navigate("/review/" + id);
        setComment("");
        setRating(0);
        fetchVendorData();
      } else {
        toast.error(msg);
      }
    });
  }

  const [page, setPage] = useState(0);
  const handleChangePage = (newPage) => {
      setPage(newPage);
  };
  const pageRCount = Math.ceil(reviewsData.length / rowsPerPage);
  
  return (
    <>
      <Header />
      <Helmet>
        <title>{TITLE_REVIEWS}</title>
        <meta name="description" content={MTAG_DESCRIPTION_REVIEWS} />
        {/* <meta name="keywords" content={MTAG_KEYWORDS_REVIEWS.join(', ')} /> */}
        <meta property="og:title" content={MTAG_TITLE_REVIEWS} />
        <meta property="og:description" content={MTAG_DESCRIPTION_REVIEWS} />
        <meta property="og:url" content={MTAG_URL_REVIEWS+`review/${id}`} />
      </Helmet>
      <div className="">
        <div className="remodeling_cols">
          <Container>
            <div className="accredited_col">
              <div className="d-flex align-items-center flex-wrap">
                <div className="bcr_accrediated py-2">
                  <div className=" bg-white">
                  <img src={IMG_URL+'users/'+companyData.cdImage} height={70} width={70} alt='' />
                  </div>
                </div>
                <div className="contect_col ms-3 py-2">
                  <h6 className="m-0 business_cols fw-bold">
                    Business Name
                  </h6>
                  <h4 className="m-0">{companyData.cdCompanyName}</h4>
                  <p>
                    <small className="">{companyData.bcName}</small>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="right_col my-3">
                <div className="d-flex align-items-center justify-content-between flex-wrap my-3">
                  <h5 className="review_colss my-2">
                    What do you think? Add your review.
                  </h5>
                  <div className="icons_cols my-2">
                    <StarRatings
                      rating={rating}
                      starRatedColor="#FFD700"
                      changeRating={changeRating}
                      numberOfStars={5}
                      name="rating"
                      starDimension="30px"
                      starSpacing="5px"
                    />
                  </div>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Tell us what you think.."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Form.Group>
                {/* <Form>
                  {["checkbox"].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type={type}
                        id={`default-${type}`}
                        label={`I have read and agree to the`}
                      />
                    </div>
                  ))}
                </Form> */}
                <div className="d-flex align-items-center justify-content-between text-start">
                  
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setComment("");
                      setRating(0);
                    }}
                    className="my-3 fw-bold"
                  >
                    Reset
                  </span>
                  {" "}
                  <Button
                    className="button_colsss my-3 fw-bold text-white"
                    onClick={leaveReview}
                  >
                    Leave Your Review
                  </Button>
                </div>
              </div>

              
              
            </Col>

            <Col lg="6" md="6" sm="12">
              <Card className="my-3 p-3">
              <h5 className="fw-normal my-2">Customer Reviews</h5>
                  <table className="w-100">
                      <thead>
                          <tr>
                              <th className="table_col fw-bold">User</th>
                              <th className="table_col fw-bold">Comments</th>
                              <th className="table_col fw-bold">Ratings</th>
                              {/* <th className="table_col fw-bold">Action</th> */}
                          </tr>
                      </thead>
                      <tbody>
                          {reviewsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                              <tr>
                                  <td>
                                      {row.custdCustomerName}
                                  </td>
                                  <td>{row.crComment}</td>
                                  <td>
                                  {row.crRating >0
                                      ? <StarRatings
                                      rating={parseFloat(row.crRating)}
                                      starRatedColor="#FFD700"
                                      numberOfStars={5}
                                      name="rating"
                                      starDimension="20px"
                                      starSpacing="2px"
                                      />
                                      : <StarRatings
                                      rating={0}
                                      starRatedColor="#FFD700"
                                      numberOfStars={5}
                                      name="rating"
                                      starDimension="20px"
                                      starSpacing="2px"
                                  />}
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
                  <div className="d-flex align-items-center justify-content-between my-2">
                      <button className="previous_col" onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
                          Previous
                      </button>
                      <span className="pages_col">Page {page + 1} of {pageRCount}</span>
                      <button className="next_col" onClick={() => handleChangePage(page + 1)} disabled={page === pageRCount - 1}>
                          Next
                      </button>
                  </div>
              </Card>
              </Col>
          </Row>

        </Container>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default Review;
