export const getLocalData = () => {
  return {
    token: localStorage.getItem("token"),
    userId: localStorage.getItem("userId"),
    userType: localStorage.getItem("userType"),
    userName: localStorage.getItem("userName"),
    userEmail: localStorage.getItem("userEmail"),
    sessionId: localStorage.getItem("sessionId"),
    companyId: localStorage.getItem("companyId"),
    customerId: localStorage.getItem("customerId"),
    userSlugTitle: localStorage.getItem("userSlugTitle"),
  };
};

export const getParamId = () => {
  var str = window.location.hash.substring(1);
  var parts = str.split("?id=");
  return parts[parts.length - 1];
};
export const redirectToErrorPage = (error, router) => {
  if (
    localStorage.getItem("userName") === "" ||
    localStorage.getItem("userName") === undefined ||
    localStorage.getItem("userName") === null
  ) {
    router("/Login");
  } else {
    router("/serverNotConnected");
  }
};
