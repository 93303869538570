import React from "react";
import { NavLink } from "react-router-dom";
import "./../Header/Header.css";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../../img/final 4.png";
import { getLocalData } from "../../config/localStrorage";
import { useNavigate } from "react-router-dom";
function Header() {
  const local = getLocalData();
  //console.log("local", local);
  const history = useNavigate();
  const logout = () => {
    localStorage.removeItem("isLogin");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    localStorage.removeItem("token");
    localStorage.removeItem("status");
    localStorage.removeItem("companyId");
    localStorage.removeItem("customerId");
    //alert("Successfully Logged Out.");
    setTimeout(() => {
      history("/");
    }, 1000);
  };
  return (
    <div>
      <div className="d-xl-block d-lg-block d-md-none d-sm-none d-none">
        <Navbar expand="lg" className="bg-body-tertiary p-1">
          <Container>
            <Navbar.Brand href="/" className="d-flex">
              <div className="image">
                <img src={logo} className="img" alt={"working"} />
                <span className="reports ms-3">Bad Client Reports</span>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="me-3">
              <Nav className="m-auto">
                <ul>
                  <li className="dfertyuio">
                    <i className="fa-solid fa-address-card"></i>
                    <br></br>
                    <NavLink to="/aboutus">About Us</NavLink>
                  </li>
                  <li className="dfertyuio">
                    <i className="fa-solid fa-address-book"></i>
                    <br></br>
                    <NavLink to="/contactus">Contact Us</NavLink>
                  </li>
                  <li className="dfertyuio">
                    <i className="fa-solid fa-blog"></i>
                    <br></br>
                    <NavLink to="/blogs">Blog</NavLink>
                  </li>
                  {local.userName != null ? (
                    <li className="dfertyuio">
                      <i className="fa-solid fa-user"></i>
                      <br></br>
                      <NavLink to={`/profiles/${local.userId}/${local.userSlugTitle}`}>Profile</NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </Nav>
            </Navbar.Collapse>
            <ul className="d-flex align-items-center fgrtio list-unstyled m-0">
              {local.userName == null && (
                <>
                  <li>
                    <NavLink
                      to="/signup"
                      className="btn fgrtp px-3 text-white d-none ms-4 d-lg-flex rounded-1"
                      style={{ color: "#fff" }}
                    >
                      Sign Up
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/login"
                      className="btn fgrtp px-3 text-white d-none ms-4 d-lg-flex rounded-1"
                    >
                      Login
                    </NavLink>
                  </li>
                </>
              )}
              {local.userName != null ? (
                <>
                <NavDropdown title={local.userName} id="basic-nav-dropdown">
                  <NavDropdown.Item as={NavLink} to={`/changepassword`}>
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>                  
                </NavDropdown>
                </>
              ) : (
                ""
              )}
            </ul>
          </Container>
        </Navbar>
      </div>

      <div className="d-xl-none d-lg-none d-md-block d-sm-block d-block">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/">
              <div className="image">
                <img src={logo} className="img" alt={"working"} />
                <span className="reports ms-3">Bad Client Reports</span>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto align-items-center">
                <Nav.Link href="/aboutus" className="my-2 text-center">
                  <i className="fa-solid fa-address-card"></i>
                  <br></br>
                  About Us
                </Nav.Link>
                <Nav.Link href="/contactus" className="my-2 text-center">
                  <i className="fa-solid fa-address-book"></i>
                  <br></br>
                  Contact Us
                </Nav.Link>
                <Nav.Link href="/blogs" className="my-2 text-center">
                  <i className="fa-solid fa-blog"></i>
                  <br></br>
                  Blog
                </Nav.Link>
                <NavLink
                  to="/signup"
                  className="btn fgrtp px-3 text-white my-2 d-lg-flex rounded-1"
                  style={{ color: "#fff" }}
                >
                  Sign Up
                </NavLink>
                <NavLink
                  to="/login"
                  className="btn fgrtp px-3 text-white my-2 d-lg-flex rounded-1"
                  style={{ color: "#fff" }}
                >
                  Login
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}

export default Header;
