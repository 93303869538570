import React from "react";
import Pagination from "./FreeTime";
function Blog() {
  return (
    <>
      <Pagination />
      {/* <h1>Comming soon......</h1> */}
    </>
  );
}

export default Blog;
