import React from 'react';
import './../Footer/Footer.css';



function Footer() {
  return (
    <>
      <section className="footers py-2">
        <div className="container">
          <div className="footer-col row align-items-center">
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 my-2">
              <p className="m-0">Copyright © 2024 -  Bad Client Reports®</p>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12 my-2">
              <div
                className="d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start sderio">

                {/* <a href="ios-app.html">
                  <i className="fab fa-apple"></i>
                </a>

                <a href="andriod-app.html">
                  <i className="fab fa-google-play mx-3"></i>
                </a> */}

                <a
                  href="https://www.facebook.com/people/Bad-Client-Reports/61560974340202/"
                  target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook"></i>
                </a>

                <a
                  href="https://x.com/clientstoavoid"
                  target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-square-x-twitter mx-3"></i>
                </a>

                <a href="https://www.instagram.com/clientstoavoid/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram-square "></i>
                </a>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
};

export default Footer;


