import React, { useState } from "react";
import { Container, Form, Row, Col,  Card } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import { API_URL, LOGIN, MTAG_DESCRIPTION_LOGIN, MTAG_KEYWORDS_LOGIN, MTAG_TITLE_LOGIN, MTAG_URL_LOGIN, TITLE_LOGIN } from "../config/Config";
import {  toast, ToastContainer  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';

function Login() {
  const history = useNavigate();
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData();
    if (userName === "" && userPassword === "") {
      toast.error("Please fill email and password");
      return;
    } else if (userName === "") {
      toast.error("Please fill email");
      return;
    } else if (userPassword === "") {
      toast.error("Please fill password");
      return;
    }

    var productUrl = API_URL + LOGIN;
    formData.append("userName", userName);
    formData.append("adminLogin", "0");
    formData.append("userPassword", userPassword);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        const status = response.data.status;
        if (status === true) {
          const userId = response.data.data.userId;
          const userName = response.data.data.userName;
          const userType = response.data.data.userType;
          const userEmail = response.data.data.userEmail;
          const sessionId = response.data.data.sessionId;
          const customerId = response.data.data.customerId;
          const companyId = response.data.data.companyId;
          const slugTitle = response.data.data.slugTitle;
          const token = response.data.token;

          if (userType !== "1") {
            localStorage.setItem("isLogin", "1");
            localStorage.setItem("logoutExpire", new Date().getTime());
            localStorage.setItem("userId", userId);
            localStorage.setItem("userName", userName);
            localStorage.setItem("sessionId", sessionId);
            localStorage.setItem("userType", userType);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("token", token);
            localStorage.setItem("status", status);
            localStorage.setItem("customerId", customerId);
            localStorage.setItem("companyId", companyId);
            localStorage.setItem("userSlugTitle", slugTitle);
            history("/profiles/" + userId+"/"+slugTitle);
            console.log(response.data.message);
            toast.success(response.data.message);
          } else {
            toast.error("Please login with valid credentials");
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_LOGIN}</title>
          <meta name="description" content={MTAG_DESCRIPTION_LOGIN} />
          {/* <meta name="keywords" content={MTAG_KEYWORDS_LOGIN.join(', ')} /> */}
          <meta property="og:title" content={MTAG_TITLE_LOGIN} />
          <meta property="og:description" content={MTAG_DESCRIPTION_LOGIN} />
          <meta property="og:url" content={MTAG_URL_LOGIN} />
      </Helmet>
      <div className="login_col my-2">
        <Container className="login_colsss2">
          <Row className="justify-content-center align-items-center h-100">
            <Col xl={4} md={12} lg={4} sm={12} className="bg-white fthyiop my-2">
              <Card className="rounded-0 border-0">
                <Card.Header className="header_colssi"></Card.Header>
                <Card.Body>
                  <Card.Text>
                    <small className="font_family">
                      Your BCR Business account is registered to the email
                      address or email addresses on file - at your local BCR -
                      for your business. Click below to have details on how to
                      set (or re-set) your BCR Business Login password sent to
                      your inbox. If we don't have your email on file you will
                      be given next steps on how to register at that time
                    </small>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={3} md={12} lg={3} sm={12} className="p-0 bg-white fthyiop my-2">
              <div className="login_cols rounded-0 border-0">
                <div className="header_cols">
                  <span className="font_family">LOGIN</span>
                </div>
                <Form id="loginFrm" onSubmit={handleSubmit} className="px-3">
                  <Form.Group className="my-2" controlId="formGroupEmail">
                    <Form.Control
                      type="text"
                      className="form-control my-4"
                      name="userName"
                      placeholder="Email"
                      id="userName"
                      autoComplete="Username"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="my-2 password_col" controlId="formGroupPassword">
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      className="form-control my-4"
                      name="userPassword"
                      placeholder="Password"
                      id="userPassword"
                      autoComplete="current-password"
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                    
                    
                    {showPassword ? <i className="fa-solid fa-eye" onClick={togglePasswordVisibility}></i> : <i className="fa-solid fa-eye-slash" onClick={togglePasswordVisibility}></i>}

                  </Form.Group>
                  <div className="login_col text-end ">
                  {/* <a
                      href="profiles"
                      className="button_colss btn my-2 text-white btn px-4 rounded-0 font_family"
                    >
                       Login
                    </a> */}
                   <button type='submit'
                      className="button_colss btn my-2 text-white btn px-4 rounded-0 font_family"
                    >
                      Login
                    </button>

                    <NavLink
                      to="/forgotpassword"
                      className="d-block forgot_password w-100 border-0 my-2 font_family"
                    >
                      Forgot Password
                    </NavLink>
                    {/* <h6 className='heading_col'>Or Login with one of the options below:</h6> */}
                  </div>
                </Form>
                {/* <div className="login_icon d-flex align-items-center justify-content-between p-3">
                                    <a href="google" className='google_col'><i className="fab fa-google"> Google</i></a>
                                    <a href="facebook" className='facabook_col'><i className="fab fa-facebook-f"> Facebook</i></a>
                                    <a href="linkdin" className='linkedin_col'><i className="fab fa-linkedin-in"> Linkdin</i></a>
                                    <a href="amazone" className='amazon_col'><i className="fab fa-amazon"> Amazon</i></a>
                                    <a href="office" className='windows_col'><i className="fab fa-windows"> Office 365</i></a>
                                </div> */}
              </div>
            </Col>
          </Row>

        </Container>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default Login;
