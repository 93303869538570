import React from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
function SignUpCustomer() {
    return (
        <>
            <Header />
            <div className="login_colsss my-5">
                <Container>
                    <div className="login_colss">
                        <h2 className='text-center touch m-0 py-3'>Sign Up as Customer</h2>
                        <Form className='p-3'>
                            <Form.Group className="mb-2" controlId="formGroupText">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2" controlId="formGroupEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email" placeholder="" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2" controlId="formGroupPhone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="number" placeholder="" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-2" controlId="formGroupEmail">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2" controlId="formGroupSelect">
                                        <Form.Label>City</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Select/province(Optional)</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <Form.Group
                                        as={Col}
                                        controlId="validationCustom05"
                                        className="mb-2"
                                    >
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Zip"

                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="login_col text-end px-3">
                                <Button variant="warning" className='button_cols my-3 fw-bold text-white'>Sign Up</Button>{' '}
                            </div>
                        </Form>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default SignUpCustomer
