import React, { useState } from "react";
import Header from "./Header";
import Footer from "../Footer/Footer";
import "./../Header/Header.css";
import logo from "../../img/inspiring-office-interior-design-modern.webp";
import logo1 from "../../img/cautious.png";
import logo2 from "../../img/b-bbee.webp";
import MasterSearch from "./MasterSearch";
import { Helmet } from 'react-helmet';
import {
  MTAG_TITLE_HOME,MTAG_DESCRIPTION_HOME,MTAG_KEYWORDS_HOME,
  MTAG_URL_HOME,
  TITLE_HOME,
} from "../../config/Config";

function Dashboard() {
  const [businessJson, setBusinessJson] = useState("");
  const [catgoryJson, setCategoryJson] = useState("");
  const [cityJson, setCityJson] = useState("");
  return (
    <div>
      <Header />
      <Helmet>
          <title>{TITLE_HOME}</title>
          <meta name="description" content={MTAG_DESCRIPTION_HOME} />
          {/* <meta name="keywords" content={MTAG_KEYWORDS_HOME.join(', ')} /> */}
          <meta property="og:title" content={MTAG_TITLE_HOME} />
          <meta property="og:description" content={MTAG_DESCRIPTION_HOME} />
          <meta property="og:url" content={MTAG_URL_HOME} />
      </Helmet>
      <section className="careers_col">
        <div className="container">
          <div className="dfsdgfert">
            <div>
              <div className="business_col my-3">
                <h1 className="text_col">Protect Your Business</h1>
                <p>BCR helps consumers find businesses that they can trust.</p>
              </div>
              <MasterSearch
                setSelectedOptionBusiness={setBusinessJson}
                setSelectedOption={setCategoryJson}
                setCityState={setCityJson}
                selectedOptionBusiness={businessJson}
                selectedOption={catgoryJson}
                cityState={cityJson}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="Featured_col">
        <div className="container">
          <div className="row my-3">
            <div className="col-lg-4 my-2">
              <div className="card align-items-center p-2 h-100">
                <img src={logo} className="card-img-top imges_collls" alt={"working"} />
                <div className="card-body">
                  <p className="card-text benefites fw-bold text-center">
                    <a href="./benefitcompany" className="fgfrtyi">
                      {" "}
                      Benefits for Companies
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2">
              <div className="card align-items-center p-2 h-100">
                <img src={logo1} className="card-img-top imges_collls" alt={"working"} />
                <div className="card-body">
                  <p className="card-text benefites fw-bold text-center">
                    <a href="benefitcustomer" className="fgfrtyi">
                      {" "}
                      Benefits for Consumers
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2">
              <div className="card p-2 align-items-center h-100">
                <img src={logo2} className="card-img-top imges_collls" alt={"working"} />
                <div className="card-body">
                  <p className="card-text benefites fw-bold text-center">
                    <a href="resolutionprocess" className="fgfrtyi">
                      {" "}
                      Our Resolution Process
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Dashboard;
