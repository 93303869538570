import React, { useState, Fragment, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import validator from "validator";
import AsyncSelect from "react-select/async";
import {
  API_URL,
  CITY_STATE_GET,
  QUESTION_COMPLAINTS,
  SETTLEMENT_LIST,
  ADD_COMPLAINTS,
  COMPLAINT_TYPE,
  AUTO_COMPANY_LIST,
  CUSTOMER_LIST,
  GET_BUSINESS_CATEGORY,CHECK_USER_EMAIL,
  CHECK_PROFANITY,
  MTAG_TITLE_REGISTER_COMPLAINT,
  MTAG_DESCRIPTION_REGISTER_COMPLAINT,
  MTAG_KEYWORDS_REGISTER_COMPLAINT,
  MTAG_URL_REGISTER_COMPLAINT,
  TITLE_REGISTER_COMPLAINT,
} from "../config/Config";
import { getLocalData } from "../config/localStrorage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import {  useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

function RegisterComplaint() {
  var local = getLocalData();
  //const history = useNavigate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [ setBcId] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [address, setAddress] = useState("");
  const [emailError, setEmailError] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isError, setIsError] = useState(false);
  const [cityState, setCityState] = useState("");
  const [zip, setZip] = useState("");
  const [quesData, setQuesData] = useState([]);
  const [description, setDescription] = useState("");
  const [settlementData, setSettlementData] = useState([]);
  const [stlId, setStlId] = useState(0);
  const [stlName, setStlName] = useState("");
  const [compType, setCompTypeData] = useState([]);
  const [ctId, setCtId] = useState(0);
  const [title, setTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setloading] = useState(true);
  const [type, setType] = useState(0);
  const [activeKey, setSelectedKey] = useState(
    local.userName === null ? "first" : "second"
  );
  const [isEmailInDb, setIsEmailInDb] = useState(false);
  const [colSm, setColSm] = useState("12");

  const [isProfanity, setIsProfanity] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  const checkProfanity = (e) => {
    setErrorMsg('');
    var productUrl = API_URL + CHECK_PROFANITY;
      const formData = new FormData();
      formData.append("text", description);
      axios({
          method: "post",
          url: productUrl,
          data: formData,
      }).then(function (response) {
          var status = response.data.status;
          var error_msg = response.data.msg;
          setIsProfanity(status);
          if(status===false){
            setErrorMsg(error_msg);
          }
      });
  };

  const getAutoCompanyList = async () => {
    var apiUrl = API_URL + AUTO_COMPANY_LIST;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        setSuggestions(response.data.data);
        // console.log(response.data);
      });
  };
  const fetchcTypeData = () => {
    var productUrl = API_URL + COMPLAINT_TYPE;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setCompTypeData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, history);
    // });
  };
  //city,state data
  const fetchSettlementData = () => {
    var productUrl = API_URL + SETTLEMENT_LIST;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        setSettlementData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, history);
    // });
  };
  const customControlStyles = (base) => ({ ...base, fontSize: 14 });
  const promiseCityStateOptions = (sstring) =>
    new Promise((resolve, reject) => {
      fetch(`${API_URL + CITY_STATE_GET}?q=${sstring}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            var filtered = result.data.map((d) => ({
              value: d.id,
              label: d.name,
              type: d.type,
            }));
            resolve(filtered);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });

  //form submit
  const handleChange = (selectedOption) => {
    setCityState(selectedOption);
  };
  const handleChanges = (selected) => {
    setSelectedOption(selected);
  };
  const pattern = new RegExp(/^\d{1,10}$/);
  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
      setCompanyEmail(email);
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const validateInDbEmail = (e) => {
    var emailcheckUrl = API_URL + CHECK_USER_EMAIL;
      const formData = new FormData();
      formData.append("emailId", companyEmail);
      axios({
          method: "post",
          url: emailcheckUrl,
          data: formData,
      }).then(function (response) {
          var status = response.data.status;
          var error_msg = response.data.msg;
          setIsEmailInDb(status);
          if(status===false){
            setEmailError(error_msg);
          }
      });
  };
  const [zipError, setzipError] = useState("");
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }
  const validateZip = (e) => {
    var zip = e.target.value;
    if (zip.length < 5 || zip.length > 5) {
      setzipError("Please enter valid zip!");
    } else if (zip.length === 5 && isNumeric(zip) === true) {
      setzipError("");
      setZip(zip);
    }
  };
  const fetchQuestionData = () => {
    var productUrl = API_URL + QUESTION_COMPLAINTS;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setQuesData(response.data.data);
      });
  };
  const fetchVendorData = async () => {
    var productUrl = API_URL + CUSTOMER_LIST + "?hdId=" + local.customerId;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        const data = response.data.data[0];
        setName(data.custdCustomerName);
        setPhoneNumber(data.custdPhoneNumber);
        setAddress(data.custdAddress);
        setZip(data.custdZip);
        const cityName = data.cityName + "," + data.regionName;
        setCityState({ label: cityName });
      });
  };
  function getBusinessCategory() {
    var productUrl = API_URL + GET_BUSINESS_CATEGORY;
    const formData = new FormData();

    axios.post(productUrl, formData).then((response) => {
      setCategoryData(response.data.data);
    });
  }
  useEffect(() => {
    if(local.userName!=null){
        setColSm("10");
    }
    getBusinessCategory();
    fetchQuestionData();
    fetchSettlementData();
    fetchcTypeData();
    getAutoCompanyList();

    if (local.customerId !== null) fetchVendorData();
  }, []);
  useEffect(() => {
    var found = suggestions.find(function (ele) {
      return ele.value === id;
    });
    setSelectedOption(found);
  }, [id, suggestions]);
  const [files, setFiles] = useState([]);

  const renderFileList = () => (
    <ul>
      {[...files].map((f, i) => (
        <li key={i}>
          <b>
            <div className="text-white css-rs23t5 ek0ml8b0">{f.type}</div>
            {f.name}
          </b>
        </li>
      ))}
    </ul>
  );
  const submitFrm = (key) => {
    var productUrl = API_URL + ADD_COMPLAINTS;
    
    const formData = new FormData(document.getElementById("complaintFrm"));
    if (key === "second") {
      if (type === 0) {
        toast.error("Please select type");
        return;
      } else if (name === "") {
        toast.error("Please fill name");
        return;
      } else if (companyEmail === "" || emailError !== "") {
        toast.error("Please fill valid  email");
        return;
      } else if (phoneNumber === "" || phoneNumber.length !== 10) {
        toast.error("Please fill phone number");
        return;
      } else if (cityState === "") {
        toast.error("Please select city,state");
        return;
      } else if (zip === "" || zip.length !== 5) {
        toast.error("Please fill valid zip code");
        return;
      } else if (isEmailInDb===false){
        toast.error("Email already exists. Please use other email.");
        return;
      }

      setSelectedKey(key);
    }

    if (selectedOption === null) {
      toast.error("Please Fill Company ");
      return;
    } else if (ctId === "4") {
      if (title === "") {
        toast.error("Please Fill Title");
        return;
      }
    } else if (description === "") {
      toast.error("Please Fill Description");
      return;
    }else if (isProfanity === false) {
      toast.error("Profanity is not allowed on this platform");
    return;
  }
    if (stlId === "0") {
      toast.error("Please Fill Desired Sattlement");
      return;
    }
    if (
      formData.get("ans[11]") === null ||
      formData.get("ans[12]") === null ||
      formData.get("ans[13]") === null
    ) {
      toast.error("Please select terms and conditions");
      return;
    }
    if (key === "third") {
      setSelectedKey(key);
    }
    formData.append("complaintUserId", local.userId);
    formData.append("userId", local.customerId);
    if (files.length > 0) {
      [...files].forEach((file, i) => {
        formData.append(`file-${i}`, file, file.name);
      });
    }
    if (selectedOption !== null) {
      formData.append("companyId", selectedOption.value);
    } else {
      formData.append("companyId", 0);
    }
    formData.append("sattlementId", stlId);
    // if (adQues === 1 && arId === 0) {
    //   toast.error("Please select age range");
    //   return;
    // }
    formData.append("isUs", "");
    formData.append("additionalQuestions", "");
    formData.append("custdZip", zip);
    
    formData.append("complaintType", ctId);
    formData.append("title", title);

    // console.log(local);
    // console.log(Array.from(formData));
    // return;
    if (key === "addComplaint") {
      setSelectedKey("third");
      setloading(false);
      axios
        .post(productUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: local.token,
          },
        })
        .then((response) => {
          if (response.data.status === true) {
            /*localStorage.setItem("isLogin", "1");
            localStorage.setItem("logoutExpire", new Date().getTime());
            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem("userName", response.data.userName);
            localStorage.setItem("sessionId", response.data.sessionId);
            localStorage.setItem("userType", response.data.userType);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("status", response.data.status);
            localStorage.setItem("customerId", response.data.customerId);
            localStorage.setItem("companyId", response.data.companyId);*/
            toast.success(response.data.msg);
            //history('/profiles/'+response.data.userId);
            navigate("/profiles/" +local.userId+"/"+local.userSlugTitle);
            
          } else {
            toast.error(response.data.msg);
          }
          setloading(true);
        });
      // .catch((error) => {
      //   redirectToErrorPage(error.message, history);
      // });
    }
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>{TITLE_REGISTER_COMPLAINT}</title>
        <meta name="description" content={MTAG_DESCRIPTION_REGISTER_COMPLAINT} />
        {/* <meta name="keywords" content={MTAG_KEYWORDS_REGISTER_COMPLAINT.join(', ')} /> */}
        <meta property="og:title" content={MTAG_TITLE_REGISTER_COMPLAINT} />
        <meta property="og:description" content={MTAG_DESCRIPTION_REGISTER_COMPLAINT} />
        <meta property="og:url" content={MTAG_URL_REGISTER_COMPLAINT+`registercomplaint/${id}`} />
    </Helmet>
      <div className="login_col">
        <Container>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={activeKey}
            activeKey={activeKey}
          >
            <Row>
              <Col sm={colSm}>
                <Nav
                  variant="pills"
                  className="flex-row"
                  activeKey={activeKey}
                  onSelect={(selectedKey) => {
                    // setSelectedKey(selectedKey);
                  }}
                >
                  <Nav.Item className="my-3 ms-3">
                    {local.userName === null && (
                      <Nav.Link eventKey={"first"}>
                        <h5 className="m-0">STEP 1</h5>
                        <p className="m-0">Tell us about yourself</p>
                      </Nav.Link>
                    )}
                  </Nav.Item>
                  <Nav.Item className="my-3 ms-3">
                    <Nav.Link eventKey={"second"}>
                      <h5 className="m-0">
                        STEP {local.userName === null ? "2" : "1"}
                      </h5>
                      <p className="m-0">Tell us about your complaint</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="my-3 ms-3">
                    <Nav.Link eventKey={"third"}>
                      <h5 className="m-0">
                        STEP {local.userName === null ? "3" : "2"}
                      </h5>
                      <p className="m-0">Review and submit your complaint</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Row>
                <Col>
                  <Form id="complaintFrm">
                    <Tab.Content>
                      {local.userName === null && (
                        <Tab.Pane eventKey={activeKey === "first" && "first"}>
                          <Row className="py-4 justify-content-center">
                            <Col lg="8">
                              <div className="login_colss p-3">
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-2"
                                      controlId="formGroupCompanyName"
                                    >
                                      <Form.Label>
                                        Type
                                        <span style={{ color: "red" }}>*</span>
                                      </Form.Label>
                                      <select
                                        className="form-control  "
                                        onChange={(e) =>
                                          setType(e.target.value)
                                        }
                                        value={type}
                                        name="type"
                                      >
                                        <option value="0">Select type</option>
                                        <option value="1">Company</option>
                                        <option value="2">Individual</option>
                                        <option value="3">
                                          Customer/Consumer
                                        </option>
                                      </select>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                {type === "1" || type === "2" || type === "3" ? (
                                  <Row>
                                    <Col lg={type === "3" ? "12" : "6"}>
                                      <Form.Group controlId="formGroupCompanyName">
                                        <Form.Label>
                                          {type !== "1"
                                            ? "Full Name"
                                            : "Company Name"}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          name="name"
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                    {type === "1" || type === "2" ? (
                                      <Col lg="6">
                                        <Form.Group
                                          className="mb-2"
                                          controlId="formGroupCompanyName"
                                        >
                                          <Form.Label>
                                            Business Category
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </Form.Label>
                                          <select
                                            className="form-control"
                                            name="cdBcId"
                                            onChange={(e) =>
                                              setBcId(e.target.value)
                                            }
                                          >
                                            <option value={0} key={0}>
                                              Select Business Category
                                            </option>
                                            {categoryData.length > 0 &&
                                              categoryData.map((res) => {
                                                return (
                                                  <option
                                                    key={res.bcId}
                                                    value={res.bcId}
                                                  >
                                                    {res.bcName}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                        </Form.Group>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </Row>
                                ) : (
                                  ""
                                )}

                                <Row>
                                  <Col lg="6">
                                    <Form.Group
                                      className="mb-2"
                                      controlId="formGroupEmail"
                                    >
                                      <Form.Label>
                                        Email
                                        <span style={{ color: "red" }}>*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="companyEmail"
                                        placeholder="Email"
                                        onChange={(e) => validateEmail(e)}
                                        onKeyUp={(e) => validateInDbEmail(e)}
                                      />
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {emailError}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Group
                                      className="mb-2"
                                      controlId="formGroupEmail"
                                    >
                                      <Form.Label>
                                        Phone
                                        <span style={{ color: "red" }}>*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        pattern="\d*"
                                        className="form-control"
                                        maxLength="10"
                                        name="phoneNumber"
                                        placeholder="Mobile Number"
                                        onChange={(e) => {
                                          setPhoneNumber(e.target.value);
                                          if (!pattern.test(e.target.value))
                                            setIsError(true);
                                          else setIsError(false);
                                        }}
                                      />
                                      {isError && (
                                        <h5 style={{ color: "red" }}>
                                          Your Mobile Number is:
                                          {isError && "Invalid"}
                                        </h5>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="4" lg="4" md="6" sm="12" className="my-2">
                                    <Form.Group
                                      className="mb-2"
                                      controlId="formGroupPassword"
                                    >
                                      <Form.Label>
                                        City/State
                                        <span style={{ color: "red" }}>*</span>
                                      </Form.Label>
                                      <AsyncSelect
                                        onChange={handleChange}
                                        value={cityState}
                                        cacheOptions
                                        placeholder="City, State"
                                        name="hdCity"
                                        defaultOptions
                                        loadOptions={promiseCityStateOptions}
                                        styles={{
                                          control: customControlStyles,
                                          option: customControlStyles,
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xl="4" lg="4" md="6" sm="12" className="my-2">
                                    <Form.Group
                                      className="mb-2"
                                      controlId="formGroupPassword"
                                    >
                                      <Form.Label>Address</Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        placeholder="Address"
                                        onChange={(e) =>
                                          setAddress(e.target.value)
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xl="4" lg="4" md="6" sm="12" className="my-2">
                                    {" "}
                                    <Form.Group>
                                      <Form.Label>Zip<span style={{ color: "red" }}>*</span></Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Zip"
                                        maxLength={5}
                                        name="zip"
                                        onChange={(e) => validateZip(e)}
                                      />
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {zipError}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <div className="login_col text-end px-3">
                                  <button
                                    type="button"
                                    onClick={() => submitFrm("second")}
                                    className="button_colss my-3 fw-bold text-white btn rounded-0 me-2"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                      )}
                      <Tab.Pane eventKey={activeKey === "second" && "second"}>
                        <Row className="py-4 justify-content-center">
                          <Col lg="8">
                            <div className="login_colss bg-white p-3">
                              <Form.Group className="my-2">
                                <Form.Label>Company</Form.Label>
                                <div id="autosuggest">
                                  <Select
                                    value={selectedOption}
                                    onChange={handleChanges}
                                    options={suggestions} 
                                    isDisabled={true}
                                    isClearable={true} // Optional: allows clearing the selection
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Complaint Type</Form.Label>
                                <select
                                  className="form-control"
                                  name="ctId"
                                  onChange={(e) => setCtId(e.target.value)}
                                >
                                  <option key={0} value={0}>
                                    Please Select
                                  </option>
                                  {compType.length > 0 &&
                                    compType.map((res) => {
                                      return (
                                        <option key={res.ctId} value={res.ctId}>
                                          {res.ctName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </Form.Group>
                              {ctId === "4" && (
                                <>
                                  <Form.Group>
                                    <Form.Label>Other :</Form.Label>
                                    <Form.Control
                                      name="title"
                                      onChange={(e) => setTitle(e.target.value)}
                                      value={title}
                                    />
                                  </Form.Group>
                                </>
                              )}
                              <Form.Group>
                                <Form.Label>Complaint Description<span style={{ color: "red" }}>*</span></Form.Label>
                                <Form.Control
                                  as="textarea"
                                  aria-label="With textarea"
                                  name="description"
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  onKeyUp={(e) => checkProfanity(e)}
                                  value={description}
                                ></Form.Control>
                                <span style={{ fontWeight: "bold", color: "red", }} >
                                  {errorMsg}
                                </span>
                              </Form.Group>
                              {/* <div className="duration my-3">
                              <h5 className="">Desired resolution</h5>
                              <p>
                                View complete descriptions of the{" "}
                                <a href="#">Types of Resolutions</a> BCR will
                                and will not pursue. Add up to three.
                              </p>
                            </div> */}

                              <Form.Group>
                                <Form.Label>Desired Sattlement</Form.Label>
                                <select
                                  className="form-control"
                                  name="stlId"
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    const split = val.split("/");
                                    setStlId(split[0]);
                                    setStlName(split[1]);
                                  }}
                                  // value={stlId}
                                >
                                  <option value={0} key={0}>
                                    Please select
                                  </option>
                                  {settlementData.length > 0 &&
                                    settlementData.map((res) => {
                                      return (
                                        <option
                                          value={res.stlId + "/" + res.stlName}
                                          key={res.stlId}
                                        >
                                          {res.stlName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </Form.Group>
                              {/* <a href="#">Add Another Settlement</a> */}
                              {quesData.length > 0 &&
                                quesData.map((res, indexKey) => {
                                  return (
                                    <Fragment key={indexKey}>
                                      <Form.Group>
                                        <Form.Label>
                                        
                                          {res.cqQuestionType === "5" ? 
                                            <>
                                            {res.cqId==='11' || res.cqId==='12' || res.cqId==='13'?
                                              
                                                <span style={{ color: "red" }}>*</span>
                                             
                                              : ""}
                                           
                                              <Form.Check
                                                type={"checkbox"}
                                                id="chk"
                                                name={"ans[" + res.cqId + "]"}
                                                label={res.cqQuestion}
                                              />
                                            </>
                                          :''}
                                        </Form.Label>
                                      </Form.Group>
                                      <Form.Group>
                                        {res.cqQuestionType === "1" && (
                                          <>
                                            <Form.Label>
                                              {res.cqQuestion}
                                            </Form.Label>
                                            <Form.Control
                                              name={"ans[" + res.cqId + "]"}
                                              type="number"
                                            />
                                          </>
                                        )}
                                        {res.cqQuestionType === "2" && (
                                          <>
                                            {res.cqQuestion}
                                            <br></br>
                                            <Form.Check
                                              type={"radio"}
                                              id="radio1"
                                              name={"ans[" + res.cqId + "]"}
                                              label={"Yes"}
                                              value={"yes"}
                                              inline
                                            />
                                            <Form.Check
                                              type={"radio"}
                                              id="radio1"
                                              name={"ans[" + res.cqId + "]"}
                                              value={"no"}
                                              label={"No"}
                                              inline
                                            />
                                          </>
                                        )}
                                      </Form.Group>
                                    </Fragment>
                                  );
                                })}
                              <Form.Group>
                                <Form.Label>
                                  <b>Upload supporting documents (optional)</b>
                                  <p>
                                    Supporting documents submitted will only be
                                    shared with the business for the purpose of
                                    resolving your complaint. Example: receipts,
                                    contracts, pictures, advertisements, etc.
                                  </p>
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  onChange={(e) => setFiles(e.target.files)}
                                />
                                {renderFileList()}
                              </Form.Group>
                              <div className="login_col text-end px-3">
                                {local.userName === null && (
                                  <button
                                    type="button"
                                    onClick={() => setSelectedKey("first")}
                                    className="button_colss my-3 fw-bold text-white btn rounded-0 me-2"
                                  >
                                    Back
                                  </button>
                                )}
                                <Button
                                  variant="warning"
                                  type="button"
                                  onClick={() => submitFrm("third")}
                                  className="button_cols my-3 fw-bold text-white rounded-0"
                                >
                                  Continue
                                </Button>{" "}
                              </div>
                            </div>
                          </Col>
                          {/* <div className="d-flex justify-content-end">
                            <a
                              href="gfgf"
                              className="button_colss my-3 fw-bold text-white btn rounded-0 me-2"
                            >
                              Save and Continue Later
                            </a>
                          </div> */}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey={activeKey === "third" && "third"}>
                        <Row className="py-4 justify-content-center">
                          <Col lg="8">
                            <div className="login_colss bg-white p-3">
                              <div className="form_colss p-3">
                                {/* <div className="d-flex justify-content-end">
                                <a href="hghgf">Edit</a>
                              </div> */}
                                <Row>
                                  <Col lg="6" md="2">
                                    <div className="heading_col">
                                      <h6>
                                        Full name:{" "}
                                        <span className="fw-normal">
                                          {name}
                                        </span>
                                      </h6>
                                      <h6>
                                        Phone:{" "}
                                        <span className="fw-normal">
                                          {phoneNumber}
                                        </span>
                                      </h6>
                                      <h6>
                                        Street address:{" "}
                                        <span className="fw-normal">
                                          {address}
                                        </span>
                                      </h6>
                                      {/* <h6>
                                      State:{" "}
                                      <span className="fw-normal">UP</span>
                                    </h6> */}
                                    </div>
                                  </Col>
                                  {/* <Col lg="6" md="2">
                                  <div className="heading_col">
                                    <h6>
                                      Last name:{" "}
                                      <span className="fw-normal">XYZ</span>
                                    </h6>
                                  </div>
                                </Col> */}
                                </Row>
                                <Row>
                                  <Col lg="6" md="2">
                                    <div className="heading_col">
                                      <h6>
                                        City/State:{" "}
                                        <span className="fw-normal">
                                          {cityState.label}
                                        </span>
                                      </h6>
                                      {/* <h6>
                                      Country:{" "}
                                      <span className="fw-normal">India</span>
                                    </h6> */}
                                    </div>
                                  </Col>
                                  <Col lg="6" md="2">
                                    <div className="heading_col">
                                      <h6>
                                        Zip:{" "}
                                        <span className="fw-normal">{zip}</span>
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="form_colss p-3 my-3">
                                {/* <div className="d-flex justify-content-end">
                                <a href="hghgf">Edit</a>
                              </div> */}
                                <Row>
                                  <Col lg="12" md="2">
                                    <div className="heading_col">
                                      <h6>
                                        Description of complaint: <br></br>
                                        <span className="fw-normal">
                                        <Form.Control readOnly
                                  as="textarea"
                                  aria-label="With textarea"
                                  name="description2"
                                  rows={5}
                                  value={description}
                                ></Form.Control>
                                        </span>
                                      </h6>

                                      <h6 className="my-4">
                                        Desired settlement: <br></br>
                                        <span className="fw-normal">
                                          {stlName}
                                        </span>
                                      </h6>

                                      {/* <h6>
                                      Money paid to business: <br></br>
                                      <span className="fw-normal">
                                        4458715484
                                      </span>
                                    </h6> */}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="login_col text-end px-3">
                                <button
                                  type="button"
                                  onClick={() => setSelectedKey("second")}
                                  className="button_colss my-3 fw-bold text-white btn rounded-0 me-2"
                                >
                                  Back
                                </button>
                                <Button
                                  variant="warning"
                                  type="button"
                                  disabled={loading === false}
                                  onClick={() => {
                                    submitFrm("addComplaint");
                                  }}
                                  className="button_cols my-3 fw-bold text-white rounded-0"
                                >
                                  Submit
                                </Button>{" "}
                              </div>
                            </div>
                          </Col>
                          {/* <div className="d-flex justify-content-end">
                            <a
                              href="gfgf"
                              className="button_colss my-3 fw-bold text-white btn rounded-0 me-2"
                            >
                              Save and Continue Later
                            </a>
                          </div> */}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Form>
                </Col>
                {/* <Col>
                  <Card className="my-4">
                    <Card.Body>
                      <Card.Text>
                        <span className="text_col fw-bold">
                          Business Headquarters Location
                        </span>
                      </Card.Text>
                      <div className="d-flex align-items-baseline">
                        <i className="fas fa-map-marker"></i>
                        <span className="ms-2 fgfdhgt">
                          ABC Dr, <br></br>Lake, MI 48362
                        </span>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <a href="fdg">
                          <i className="fas fa-phone-alt"></i>
                          <span className="ms-2 fgfdhgt">7894561338</span>
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                  <Card className="my-3">
                    <Card.Body>
                      <p>
                        If you have any questions or concerns, please contact
                        the BCR assigned to your complaint:
                      </p>
                      <Card.Text>
                        <span className="text_col fw-bold">
                          BCR of Detroit & Eastern Michigan
                        </span>
                      </Card.Text>
                      <div className="d-flex align-items-baseline">
                        <i className="fas fa-map-marker"></i>
                        <span className="ms-2 fgfdhgt">
                          12345 W. XYZ Rd<br></br>
                          ABC 202<br></br>
                          Southfield, MI 1234-4587
                        </span>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <a href="fdg">
                          <i className="fas fa-envelope"></i>
                          <span className="ms-2 fgfdhgt">Email this BCR</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <a href="fdg">
                          <i className="fas fa-phone-alt"></i>
                          <span className="ms-2 fgfdhgt">(888) 544-7366</span>
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                </Col> */}
              </Row>
            </Row>
            <ToastContainer />
          </Tab.Container>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default RegisterComplaint;
