import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useParams } from "react-router-dom";
import { API_URL, IMG_URL, SEARCH_COMPANIES,COMPLAINTS_LIST, MTAG_URL_DETAILS, MTAG_TITLE_DETAILS, MTAG_DESCRIPTION_DETAILS, MTAG_KEYWORDS_DETAILS, TITLE_DETAILS } from "../config/Config";
import { getLocalData } from "../config/localStrorage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRatings from "react-star-ratings";
import { Helmet } from 'react-helmet';

const rowsPerPage = 3; // Number of rows to display per page
const rowsPerPager = 3; // Number of rows to display per page

function Details() {
  const { id,userSlugTitle } = useParams();
  const linkStyle = {
    color: '#2770c4', // Text color
    textDecoration: 'none', // Remove underline by default
    border: '1px solid #2770c4', // Border style
    padding: '5px 10px', // Padding around the text
    borderRadius: '5px', // Rounded corners
  };
  const [complaintsData, setComplaintsData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);
  const local = getLocalData();
  function searchFrm() {
    var productUrl = API_URL + SEARCH_COMPANIES;
    const formData = new FormData();
    formData.append("id", id);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setCompanyData(response.data.data[0]);
    });
  }

  function fetchComplaintsData() {
    var productUrl = API_URL + COMPLAINTS_LIST;
    const formData = new FormData();
    formData.append("customerId", local.customerId);
    formData.append("companyId", id);
    formData.append("complaintId", "");
    // console.log(Array.from(formData));

    axios
      .post(productUrl, formData, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        setComplaintsData(response.data.data);
        setReviewsData(response.data.reviews);
      });
  }
  useEffect(() => {
    searchFrm();
    fetchComplaintsData();
  }, []);
  
  
  const [page, setPage] = useState(0);
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const pageCount = Math.ceil(complaintsData.length / rowsPerPage);

    const [pager, setPager] = useState(0);
    const handleChangePager = (newPager) => {
        setPager(newPager);
    };
    const pageRCount = Math.ceil(reviewsData.length / rowsPerPager);

  return (
    <>
      <Header />
      <Helmet>
        <title>{TITLE_DETAILS}</title>
        <meta name="description" content={MTAG_DESCRIPTION_DETAILS} />
        {/* <meta name="keywords" content={MTAG_KEYWORDS_DETAILS.join(', ')} /> */}
        <meta property="og:title" content={MTAG_TITLE_DETAILS} />
        <meta property="og:description" content={MTAG_DESCRIPTION_DETAILS} />
        <meta property="og:url" content={MTAG_URL_DETAILS+`details/${id}/${userSlugTitle}`} />
      </Helmet>
      <div className="">
        <div className="remodeling_cols">
          <Container>
            <div className="accredited_col">
              <div className="d-flex align-items-center">
                {companyData.cdImage !== "" && (
                  <div className="bcr_accrediated">
                    <div className=" bg-white">
                      <img src={IMG_URL+'users/'+companyData.cdImage} height={70} width={70} alt='' />
                    </div>
                  </div>
                )}
                <div className="contect_col ms-3">
                  <h6 className="m-0 business_cols">Business Profile</h6>
                  <h3 className="m-0">{companyData.cdCompanyName}</h3>
                  <p>
                    <small className="fw-bold">{companyData.bcName}</small>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg="4" md="6" className="my-2">
              <Card className="my-3 dfrty">
                <Card.Body>
                  <Card.Text as="div">
                    <h5 className="fw-normal my-2">Contact Information</h5>
                  </Card.Text>
                  <div className="d-flex align-items-baseline">
                    <i className="fas fa-map-marker-alt"></i>
                    <span className="ms-2 fgfdhgt">
                      {companyData.cdAddress}
                      <br></br>
                      {companyData.cityName}, {companyData.regionCode},
                      {companyData.cdPinCode}
                    </span>
                  </div>

                  <div className="d-flex align-items-baseline my-2">
                    
                      <i className="fab fa-firefox-browser"></i>
                      <span className="ms-2 fgfdhgt">Visit Website</span>
                      
                  </div>

                  <div className="d-flex align-items-baseline">
                    
                      <p className="m-0">
                        <i className="fas fa-envelope"></i>{" "}
                        <a href={"mailto:" + companyData.cdEmail}>
                          <span className="ms-2 fgfdhgt">Email this BCR</span>
                        </a>
                      </p>
                   
                  </div>

                  <div className="d-flex align-items-baseline my-2">
                   
                      <p>
                        <i className="fas fa-phone-alt"></i>{" "}
                        <a href={"tel:" + companyData.cdPhoneNumber}>
                          <span className="ms-2 fgfdhgt">
                            {companyData.cdPhoneNumber}
                          </span>
                        </a>
                      </p>
                   
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="8" md="6" className="my-2">
              <Card className="mb-3 ">
              <Card.Body>
                  <Card.Text as="div">
                      <h5 className="fw-normal my-2">Customer Reviews</h5>
                     
                          <small>
                              <div className="d-flex align-items-baseline">
                              
                                <a
                                  href={local.userName === null ? "#" : "/review/" + id}
                                  onClick={() =>
                                    local.userName === null
                                      ? toast.error("Please login first")
                                      : ""
                                  }
                                >
                                  <span className="registercomplaint">
                                    Be the First to Review!
                                  </span>
                                </a>
                            </div>
                          </small>
                      
                  </Card.Text>
                  <div >
                    <div className="table_collsss">

                    {reviewsData.length>0 ?
                      <>
                          <table className="dfrtyuyo">
                            <thead>
                                <tr>
                                    <th className="table_col fw-bold">User</th>
                                    <th className="table_col fw-bold">Comments</th>
                                    <th className="table_col fw-bold">Ratings</th>
                                    {/* <th className="table_col fw-bold">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {reviewsData.slice(pager * rowsPerPager, pager * rowsPerPager + rowsPerPager).map((row) => (
                                    <tr>
                                        <td>
                                           <small>{row.custdCustomerName}</small>
                                        </td>
                                        <td><small>{row.crComment}</small></td>
                                        <td>
                                        {row.crRating >0
                                            ? <StarRatings
                                            rating={parseFloat(row.crRating)}
                                            starRatedColor="#FFD700"
                                            numberOfStars={5}
                                            name="rating"
                                            starDimension="20px"
                                            starSpacing="2px"
                                            />
                                            : <StarRatings
                                            rating={0}
                                            starRatedColor="#FFD700"
                                            numberOfStars={5}
                                            name="rating"
                                            starDimension="15px"
                                            starSpacing="1px"
                                        />}
                                        </td>
                                        {/* <td>
                                            {row.cStatus === "3"
                                                ? <div className="d-flex align-items-center"><a href="/makepayment" className="fgrtps d-none d-lg-flex rounded-1">
                                                    Request Resolution
                                                </a></div>
                                                : ""}
                                            <div className="d-flex align-items-center"><a href={"/complaintsdetails/" + row.cSlugName} className="fgrtps d-none d-lg-flex rounded-1">
                                                View
                                            </a></div>
                                            {row.cStatus === "3"
                                                ? <div className="d-flex align-items-center"><a href="#" onClick={(id) => cancelComplaint(row.cId)} className="fgrtps d-none d-lg-flex rounded-1">
                                                Cancel
                                            </a></div>
                                                : ""}

                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between my-2">
                            <button className="previous_col" onClick={() => handleChangePager(pager - 1)} disabled={pager === 0}>
                                Previous
                            </button>
                            <span className="pages_col">Page {pager + 1} of {pageRCount}</span>
                            <button className="next_col" onClick={() => handleChangePager(pager + 1)} disabled={pager === pageRCount - 1}>
                                Next
                            </button>
                        </div>
                      </>
                      :
                      <>
                          <div className="d-flex align-items-center justify-content-between my-2">
                              <span> No Reviews Found</span>                                         
                          </div>
                      </>}
                          
                    </div>
                  </div>
              </Card.Body>
          </Card>

              <Card className="mb-3 ">
              <Card.Body>
                  <Card.Text as="div">
                      <h5 className="fw-normal my-2">Complaints</h5>
                     
                          <small>
                              <div className="d-flex align-items-baseline">
                              <a href={"/registercomplaint/" + id}>
                                <span className="registercomplaint">
                                {local.companyId !== id ? "File a Complaint" : ""}

                                </span>
                              </a>
                            </div>
                          </small>
                      
                  </Card.Text>
                  <div className="table_collsss">

                    {complaintsData.length>0 ?
                    <>
                        <table className="dfrtyuio">
                            <thead>
                                <tr>
                                    <th className="table_col fw-bold">Complaint User</th>
                                    <th className="table_col fw-bold">Compaint</th>
                                    <th className="table_col fw-bold">Status</th>
                                    <th className="table_col fw-bold">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {complaintsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,ind) => (
                                    <tr key={ind}>
                                    <td>
                                    <small> {row.complaintUserName} </small>
                                        </td>
                                    <td><small>{row.cTitle}</small></td>
                                    <td><small>{row.status}</small></td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                          <a href={"/complaint/" + row.slugTitle+"/"+ row.cSlugName} style={linkStyle} className="fgrtps d-lg-flex rounded-1">
                                        <small>View</small>
                                        </a></div>
                                    
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between my-2">
                            <button className="previous_col" onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
                                Previous
                            </button>
                            <span className="pages_col">Page {page + 1} of {pageCount}</span>
                            <button className="next_col" onClick={() => handleChangePage(page + 1)} disabled={page === pageCount - 1}>
                                Next
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <div className="d-flex align-items-center justify-content-between my-2">
                            <span> No Complaints Found</span>                                         
                        </div></>}

                  </div>
              </Card.Body>
          </Card>
            </Col>
            
          </Row>
        </Container>
        <Container className="mb-5">
          <Row>
            <Col lg="4" md="6" className="my-2">
              <Card className="my-3 h-100">
                <Card.Body>
                  <Card.Text as="div">
                    <h5 className="fw-normal my-2 services_colss">
                      Products & Services
                    </h5>
                  </Card.Text>
                  {companyData.cdProductsServices !== '' ? 
                        <div dangerouslySetInnerHTML={{__html: companyData.cdProductsServices}}></div>
                    :
                    <>
                    <p className="services_colss fw-bold m-0">
                        After Hours Remodeling, LLC provides the following services
                    </p>
                    <ListGroup>
                        <ListGroup.Item>Roofing Contractors</ListGroup.Item>
                        <ListGroup.Item>Bathroom</ListGroup.Item>
                        <ListGroup.Item>Flat Roofing Contractors</ListGroup.Item>
                        <ListGroup.Item>Floor Installation</ListGroup.Item>
                        <ListGroup.Item>Flooring Contractors</ListGroup.Item>

                        <ListGroup.Item>Hardwood Floor Contractors</ListGroup.Item>
                        <ListGroup.Item>Hardwood Floors</ListGroup.Item>
                        <ListGroup.Item>Home Improvement</ListGroup.Item>
                        <ListGroup.Item>Home Repair</ListGroup.Item>
                        <ListGroup.Item>Home Services</ListGroup.Item>

                        <ListGroup.Item>Kitchen Cabinets</ListGroup.Item>
                        <ListGroup.Item>Raised Floor</ListGroup.Item>
                        <ListGroup.Item>Roofing Consultants</ListGroup.Item>
                    </ListGroup>
                    </>
                    }
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" md="6" className="my-2">
              <Card className="my-3 h-100">
                <Card.Body>
                  <Card.Text as="div">
                    <h5 className="fw-normal my-2 services_colss">
                      Business Details
                    </h5>
                  </Card.Text>
                  {companyData.cdBusinessDetails !== '' ? 
                        <div dangerouslySetInnerHTML={{__html: companyData.cdBusinessDetails}}></div>
                    :
                    <>
                    <p className="fw-bold m-0">Location of This Business</p>
                    <p className="m-0">
                      21 Providence Point Ln, Hartwell, GA 30643-8066
                    </p>
                    {/* <div className="d-flex align-items-baseline">
                      <a href="fdg">
                        <i className="fas fa-envelope"></i>
                        <span className="ms-2 fgfdhgt">Email this Business</span>
                      </a>
                    </div> */}
                    <p>
                      <span className="fw-bold">BCR File Opened</span> :4/11/2019
                    </p>
                    <p>
                      <span className="fw-bold">Years in Business</span> :6
                    </p>
                    <p>
                      <span className="fw-bold">Business Started</span> :3/11/2018
                    </p>
                    <p>
                      <span className="fw-bold">Type of Entity</span> :Limited
                      Liability Company(LLC)
                    </p>
                    <p className="fw-bold m-0">Business Management</p>
                    <p>Mr. Adam Jones, Owner</p>

                    <div className="contact_infoermation">
                      <h6 className="fw-bold">Contact Information</h6>
                      <p className="m-0">Principal</p>
                      <p className="m-0">Mr. Adam Jones, Owner</p>
                      <p className="m-0">Customer Contact</p>
                      <p className="">Mr. Adam Jones, Owner</p>
                    </div>

                    {/* <div className="contact_infoermation">
                      <h6 className="fw-bold m-0">
                        Additional Contact Information
                      </h6>
                      <p className="m-0">Email Addresses</p>
                      <div className="d-flex align-items-baseline">
                        <a href="fdg">
                          <i className="fas fa-envelope"></i>
                          <span className="ms-2 fgfdhgt">
                            Email this Business
                          </span>
                        </a>
                      </div>
                    </div> */}
                    </>
                    }
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" md="6" className="my-2">
              <Card className="my-3 dfrty">
                <Card.Body>
                  <Card.Text as="div">
                    <h5 className="fw-normal my-2">
                      BCR Rating
                    </h5>
                  </Card.Text>
                  <div className="image_cols">
                    <span className="text-blue-brand me-3 font-bold">{companyData.bcrRating}</span>
                  </div>
                  <div className="accrediated_col my-2">
                    <a href="#" className="fdgftyt_col">
                    <p>{companyData.bcrRatingRemarks}</p> 
                    </a>
                  </div>
                  <p className="customer_review">
                      Customer Reviews are not used in the <br></br> calculation
                      of BCR Rating
                  </p>
                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default Details;
