import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import validator from "validator";
import {
  API_URL,
  CITY_STATE_GET,
  GET_BUSINESS_CATEGORY,
  SIGN_UP,CHECK_USER_EMAIL,CHECK_USER_PHONE_NUMBER,
  MTAG_TITLE_SIGN_UP,
  MTAG_DESCRIPTION_SIGN_UP,
  MTAG_KEYWORDS_SIGN_UP,
  MTAG_URL_SIGN_UP,
  TITLE_SIGN_UP,
} from "../config/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { Helmet } from 'react-helmet';
function SignUp() {
  const history = useNavigate();
  const [isEmailInDb, setIsEmailInDb] = useState(false);
  const [isPhoneNumberInDb, setIsPhoneNumberInDb] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [ setAddress] = useState("");
  const [cityState, setCityState] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isPhoneDupError, setIsPhoneDupError] = useState(false);
  const [bcId, setBcId] = useState(0);
  const pattern = new RegExp(/^\d{1,10}$/);
  const [emailError, setEmailError] = useState("");
  const [zip, setZip] = useState("");
  const [zipError, setzipError] = useState("");
  const [type, setType] = useState("0");
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }
  const validateZip = (e) => {
    var zip = e.target.value;
    if (zip.length < 5 || zip.length > 5) {
      setzipError("Please enter valid zip!");
    } else if (zip.length === 5 && isNumeric(zip) === true) {
      setzipError("");
      setZip(zip);
    }
  };
  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
      setCompanyEmail(email);
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const validateInDbEmail = (e) => {
    var emailcheckUrl = API_URL + CHECK_USER_EMAIL;
      const formData = new FormData();
      formData.append("emailId", companyEmail);
      axios({
          method: "post",
          url: emailcheckUrl,
          data: formData,
      }).then(function (response) {
          var status = response.data.status;
          var error_msg = response.data.msg;
          setIsEmailInDb(status);
          if(status===false){
            setEmailError(error_msg);
          }
      });
  };

  const validateInDbPhoneNumber = (e) => {
    var phonecheckUrl = API_URL + CHECK_USER_PHONE_NUMBER;
      const formData = new FormData();
      formData.append("phoneNumber", phoneNumber);
      axios({
          method: "post",
          url: phonecheckUrl,
          data: formData,
      }).then(function (response) {
          var status = response.data.status;
          var error_msg = response.data.msg;
          setIsPhoneNumberInDb(status);
          if(status===false){
            setIsPhoneDupError(error_msg);
          }
      });
  };

  //city,state data
  const customControlStyles = (base) => ({ ...base, fontSize: 14 });
  const promiseCityStateOptions = (sstring) =>
    new Promise((resolve, reject) => {
      fetch(`${API_URL + CITY_STATE_GET}?q=${sstring}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            var filtered = result.data.map((d) => ({
              value: d.id,
              label: d.name,
              type: d.type,
            }));
            resolve(filtered);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });
  useEffect(() => {
    getBusinessCategory();
  }, []);
  function getBusinessCategory() {
    var productUrl = API_URL + GET_BUSINESS_CATEGORY;
    const formData = new FormData();

    axios.post(productUrl, formData).then((response) => {
      setCategoryData(response.data.data);
    });
  }
  //form submit
  const handleChange = (selectedOption) => {
    setCityState(selectedOption);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (type === "0") {
      toast.error("Please select type");
      return;
    }
    if (companyName === "") {
      toast.error("Please fill name");
      return;
    } else if (bcId === 0 && type !== "3") {
      toast.error("Please select business category");
      return;
    } else if (companyEmail === "" || emailError !== "") {
      toast.error("Please fill valid email");
      return;
    } else if (phoneNumber === "" || phoneNumber.length !== 10) {
      toast.error("Please fill phone number");
      return;
    } else if (cityState === "") {
      toast.error("Please select city, state");
      return;
    }else if (isEmailInDb===false){
      toast.error("Email already exists. Please use other email.");
      return;
    }else if (isPhoneNumberInDb===false){
      toast.error("Phone number already exists. Please use other phone number.");
      return;
    }else if (zip === "" || zip.length !== 5) {
        toast.error("Please fill valid zip code");
        return;
    }
    //  else if (address === "") {
    //   toast.error("Please fill address");
    //   return;
    // } else if (zip === "" || zip.length !== 5) {
    //   toast.error("Please fill valid zip code");
    //   return;
    // }

    // setLoading(false);
    var productUrl = API_URL + SIGN_UP;
    const formData = new FormData(document.getElementById("companyFrm"));

    // console.log(Array.from(formData));
    // return;
    axios.post(productUrl, formData).then((response) => {
      if (response.data.success === true) {
        // console.log(response);
        toast.success(response.data.message);
        alert(response.data.message);
        history("/");
      } else {
        toast.error(response.data.message);
        setLoading(true);
      }
    });
  };
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_SIGN_UP}</title>
          <meta name="description" content={MTAG_DESCRIPTION_SIGN_UP} />
          {/* <meta name="keywords" content={MTAG_KEYWORDS_SIGN_UP.join(', ')} /> */}
          <meta property="og:title" content={MTAG_TITLE_SIGN_UP} />
          <meta property="og:description" content={MTAG_DESCRIPTION_SIGN_UP} />
          <meta property="og:url" content={MTAG_URL_SIGN_UP} />
      </Helmet>
      <div className="my-3">
        <Container>
          <Row className="align-items-center justify-content-center h-100">
            <Col xl="8" lg="8" sm="12">
              <Card>
                <Card.Body>
                  <div className="">
                    <h2 className="text-center touch m-0 py-3">Sign Up</h2>
                    <p style={{ paddingLeft: "5px" }}>
                      <small>
                        Required fields are marked{" "}
                        <span style={{ color: "red" }}>*</span>
                      </small>
                    </p>
                    <Form className="p-3" id="companyFrm" onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group className="mb-2" controlId="formGroupCompanyName">
                            <Form.Label>
                              Type<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <select
                              className="form-control  "
                              onChange={(e) => setType(e.target.value)}
                              value={type}
                              name="type"
                            >
                              <option value="0">Select type</option>
                              <option value="1">Company</option>
                              <option value="2">Individual Seller</option>
                              <option value="3">Customer/Consumer</option>
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>
                      {type === "1" || type === "2" || type === "3" ? (
                        <Row>
                          <Col lg={type === "3" ? "12" : "6"}>
                            <Form.Group controlId="formGroupCompanyName">
                              <Form.Label>
                                {type !== "1" ? "Full Name" : "Company Name"}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                onChange={(e) => setCompanyName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          {type === "1" || type === "2" ? (
                            <Col lg="6" md="12" sm="12">
                              <Form.Group
                                className="mb-2"
                                controlId="formGroupCompanyName"
                              >
                                <Form.Label>
                                  Business Category
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="cdBcId"
                                  onChange={(e) => setBcId(e.target.value)}
                                >
                                  <option value={0} key={0}>
                                    Select Business Category
                                  </option>
                                  {categoryData.length > 0 &&
                                    categoryData.map((res) => {
                                      return (
                                        <option key={res.bcId} value={res.bcId}>
                                          {res.bcName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </Form.Group>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      ) : (
                        ""
                      )}

                      <Row>
                        <Col lg="6" md="12" sm="12">
                          <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label>
                              Email<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="companyEmail"
                              placeholder="Email"
                              onChange={(e) => validateEmail(e)}
                              onKeyUp={(e) => validateInDbEmail(e)}
                            />
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {emailError}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="12" sm="12">
                          <Form.Group className="mb-2" controlId="formGroupPhoneNumber">
                            <Form.Label>
                              Phone<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              pattern="\d*"
                              className="form-control"
                              maxLength="10"
                              name="phoneNumber"
                              placeholder="Mobile Number"
                              onKeyUp={(e) => validateInDbPhoneNumber(e)}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                                if (!pattern.test(e.target.value)) setIsError(true);
                                else setIsError(false);
                              }}
                            />
                            {isError && (
                              <h5 style={{ color: "red" }}>
                                Your Mobile Number is:
                                {isError && "Invalid"}
                              </h5>
                            )}
                            {isPhoneDupError && (
                              <h5 style={{ color: "red" }}>                                
                                {isPhoneDupError}
                              </h5>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12" className="my-2">
                          <Form.Group className="mb-2" controlId="formGroupPassword">
                            <Form.Label>
                              City, State<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <AsyncSelect
                              onChange={handleChange}
                              value={cityState}
                              cacheOptions
                              placeholder="City, State"
                              name="hdCity"
                              defaultOptions
                              loadOptions={promiseCityStateOptions}
                              styles={{
                                control: customControlStyles,
                                option: customControlStyles,
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12" className="my-2">
                          <Form.Group className="mb-2" controlId="formGroupPassword">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="address"
                              placeholder="Address"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12" className="my-2">
                          {" "}
                          <Form.Group>
                            <Form.Label>Zip<span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Zip"
                              maxLength={5}
                              name="zip"
                              onChange={(e) => validateZip(e)}
                            />
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {zipError}
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="login_col text-end px-3">
                        {!loading && <div>Please wait...</div>}
                        {loading && (
                          <Button
                            variant="warning"
                            type="submit"
                            className="button_cols my-3 fw-bold text-white"
                          >
                            Sign Up
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default SignUp;
