import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_URL, FORGOT_PASSWORD, MTAG_DESCRIPTION_FORGOT_PASSWORD, MTAG_KEYWORDS_FORGOT_PASSWORD, MTAG_TITLE_FORGOT_PASSWORD, MTAG_URL_FORGOT_PASSWORD, TITLE_FORGOT_PASSWORD } from "../config/Config";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Helmet } from 'react-helmet';

function Login() {
    const [emailError, setEmailError] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const validateEmail = (e) => {
        var email = e.target.value;
        if (isNaN(Number(email))){
            if (validator.isEmail(email)) {
              setEmailError("");
              setEmail(email);
            } else {
              setEmailError("Enter valid Email!");
            }
        }else {
            setEmailError("");
            setEmail(email);
        }
    };
    function verifyEmail() {
        if (email === "") {
            toast.error("Please enter valid email or phone");
            return;
        }
        setLoading(false);
        var productUrl = API_URL + FORGOT_PASSWORD;
        const formData = new FormData();
        formData.append("email", email);
        axios({
            method: "post",
            url: productUrl,
            data: formData,
        }).then(function (response) {
            let msg = response.data.msg;
            if (response.data.status === true) {
                toast.success(msg);
                setLoading(true);
                setTimeout(() => {
                    navigate("/login");
                }, 5000);
            } else {
                setLoading(true);
                toast.error(msg);
            }
        });
    }
    return (
        <>
            <Header />
            <Helmet>
                <title>{TITLE_FORGOT_PASSWORD}</title>
                <meta name="description" content={MTAG_DESCRIPTION_FORGOT_PASSWORD} />
                {/* <meta name="keywords" content={MTAG_KEYWORDS_FORGOT_PASSWORD.join(', ')} /> */}
                <meta property="og:title" content={MTAG_TITLE_FORGOT_PASSWORD} />
                <meta property="og:description" content={MTAG_DESCRIPTION_FORGOT_PASSWORD} />
                <meta property="og:url" content={MTAG_URL_FORGOT_PASSWORD} />
            </Helmet>
            <div className="login_col">
                <Container className="ghdtsrers">
                    <Row className="align-items-center justify-content-center h-100">
                        <Col xl={6} md={12} lg={6}>
                            <div className="login_colss p-3">
                                <h2 className='text-center touch m-0 py-3'>Forgot Password</h2>
                                <Form.Group className="mb-2 p-1" controlId="formGroupEmail">
                                    <Form.Label>Email / Phone:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email or Phone"
                                        onChange={(e) => validateEmail(e)}
                                    />
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            color: "red",
                                        }}
                                    >
                                        {emailError}
                                    </span>
                                </Form.Group>
                                <div className="login_col text-end">
                                {!loading && <div>Please wait...</div>}
                                {loading && (
                                    <Button
                                        className="button_cols my-4"
                                        type="button"
                                        onClick={verifyEmail}
                                    >
                                        Send
                                    </Button>
                                )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
            <Footer />
        </>
    );
}

export default Login;
