import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import { getLocalData } from "../config/localStrorage";
import {
  API_URL,
  MTAG_DESCRIPTION_BUSINESS_DETAILS,
  MTAG_KEYWORDS_BUSINESS_DETAILS,
  MTAG_TITLE_BUSINESS_DETAILS,
  MTAG_URL_BUSINESS_DETAILS,
  MY_PROFILE,
  TITLE_BUSINESS_DETAILS,
  UPDATE_PROFILE_BUSINESS_DETAILS,
} from "../config/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';

function EditBusinessDetails() {
  const { id } = useParams();
  const history = useNavigate();
  const local = getLocalData();
  const [loading, setLoading] = useState(true);
  const [editorValue, setEditorValue] = useState('');

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline'],
      //['link', 'image'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      //[{ script: 'sub' }, { script: 'super' }],
      //[{ color: [] }, { background: [] }],
      //[{ align: [] }],
      //['clean']
    ],
  };
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    //'link', 'image',
    //'color', 'background',
    //'align', 'script'
  ];
  
  function getBusinessDetails() {
    var productUrl = API_URL + MY_PROFILE;
    const formData = new FormData();
    formData.append("id", id);
    axios({
        method: "post",
        url: productUrl,
        data: formData,
    }).then(function (response) {
      setEditorValue(response.data.data[0].businessDetails);       
    });
  }
  useEffect(() => {    
    getBusinessDetails();
  }, []);

  const handleChange = (value) => {
    setEditorValue(value);
  };
  
  //form submit  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (editorValue === "") {
      toast.error("Please write few lines about business details!");
      return;
    } 
    setLoading(false);
    var productUrl = API_URL + UPDATE_PROFILE_BUSINESS_DETAILS;
    const formData = new FormData(document.getElementById("companyFrm"));
    formData.append("editorText", editorValue);
    formData.append("userId", local.userId);
    axios.post(productUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: local.token,
      }
    }).then((response) => {
      if (response.data.success === true) {
        // console.log(response);
        toast.success(response.data.message);
        alert(response.data.message);
        history("/profiles/"+local.userId+"/"+local.userSlugTitle);
      } else {
        toast.error(response.data.message);
        setLoading(true);
      }
    });
  };
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_BUSINESS_DETAILS}</title>
          <meta name="description" content={MTAG_DESCRIPTION_BUSINESS_DETAILS} />
          {/* <meta name="keywords" content={MTAG_KEYWORDS_BUSINESS_DETAILS.join(', ')} /> */}
          <meta property="og:title" content={MTAG_TITLE_BUSINESS_DETAILS} />
          <meta property="og:description" content={MTAG_DESCRIPTION_BUSINESS_DETAILS} />
          <meta property="og:url" content={MTAG_URL_BUSINESS_DETAILS+`editbusinessdetails/${id}`} />
      </Helmet>
      <div className="my-3">
        <Container>
          <Row className="align-items-center justify-content-center h-100">
            <Col xl="8" lg="8" sm="12">
              <Card>
                <Card.Body>
                  <div className="">
                    <h2 className="text-center touch m-0 py-3">Edit Business Details</h2>                    
                    <Form className="p-3" id="companyFrm" onSubmit={handleSubmit}>                       
                      <Row>
                      <Col xl="12" lg="12" md="12" sm="12" className="my-2">
                        <ReactQuill
                          value={editorValue}
                          onChange={(e) => handleChange(e)}
                          modules={modules}
                          formats={formats}
                        />                    
                      </Col>
                      </Row>
                      <div className="login_col text-end px-3">
                        {!loading && <div>Please wait...</div>}
                        {loading && (
                          <Button
                            variant="warning"
                            type="submit"
                            className="button_cols my-3 fw-bold text-white"
                          >
                            Save Details
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default EditBusinessDetails;
