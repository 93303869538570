const isLiveServer = 1;

var API_URL = "https://www.badclientreports.com/app/api/";
var IMG_URL = "https://www.badclientreports.com/app/api/";
var WEB_URL = "https://www.badclientreports.com/";

if (isLiveServer==0) {
  API_URL = "http://bcr.local/app/api/";
  IMG_URL = "http://bcr.local/app/api/";
  WEB_URL = "http://localhost:3000/";
}else if (isLiveServer==2) {
  var API_URL = "https://bcr.zcpl.net/app/api/";
  var IMG_URL = "https://bcr.zcpl.net/app/api/";
  var WEB_URL = "https://bcr.zcpl.net/";
}


/* Square Payment Settings */
var SQUARE_APPLICATION_ID = "sandbox-sq0idb-dqBKVRf3N_aMMpdXgyI_Tw";
var SQUARE_LOCATION_ID = "LSX03RMMMNSR3";
/* Square Payment Settings */

var LOGIN = "authenticate/login";
var SIGN_UP = "authenticate/signUp";
var BUSSINESS_CATEGORY = "webHomeApi/getBusinessCategory";
var SEARCH_BUSSINESS_CATEGORY = "webHomeApi/searchBusinessCategory";
var BUSSINESS_LIST = "webHomeApi/getBusinessList";
var SEARCH_BUSSINESS_LIST = "webHomeApi/searchBusinessList";
var SEARCH_COMPANIES = "webHomeApi/searchCompanies";
var SETTLEMENT_LIST = "webHomeApi/listSattlements";
var QUESTION_COMPLAINTS = "webHomeApi/complaintQuestions";
var ADD_COMPLAINTS = "webHomeApi/addComaplaints";
var COMPLAINTS_LIST = "webHomeApi/listComplaints";
var PAY_GRADES = "webHomeApi/payGrades";
var AGE_RANGES = "webHomeApi/ageRanges";
var SERVICE_BRANCHES = "webHomeApi/branchServices";
var STATUS = "webHomeApi/status";
var AUTO_COMPANY_LIST = "webHomeApi/getAutoCompanyList";
var COMPLAINT_TYPE = "webHomeApi/listType";
var BLOG_COMMENTS = "webHomeApi/listBlogComments";
var CUSTOMER_LIST = "customers/customerList";
var ADD_BLOG_COMMENTS = "webHomeApi/addBlogComments";
var FORGOT_PASSWORD = "webHomeApi/forgotPassword";
var GET_BUSINESS_CATEGORY = "authenticate/getBusinessCategory";
var ADD_REVIEW = "webHomeApi/addReview";
var ADD_CONTACT_US = "webHomeApi/addContactUs";
var UPDATE_PROFILE_DATA = "customers/updateProfileData";
var CHANGE_PASSWORD = "Companies/changePassword";
var UPDATE_CONTACT_DETAILS_PROFILE = "Companies/updateContactDetails";
var UPDATE_PROFILE_PRODUCT_SERVICES = "Companies/updateProductServicesDetails";
var UPDATE_PROFILE_BUSINESS_DETAILS = "Companies/updateBusinessDetails";

//Custom
var CITY_STATE_GET = "Customs/cityState";
var BLOG_LIST = "Customs/listBlogs";
var BLOG_RELATED_LIST = "Customs/listRelatedBlogs";
var SAVE_PAYMENT_DETAILS = "Payments/savePaymentDetails";
var MY_PROFILE = "webHomeApi/myProfile";
var CHANGE_COMPLAINT_STATUS = "webHomeApi/changeComplaintStatus";
var COMPLAINT_DETAILS = "webHomeApi/complaintDetails";
var CHECK_USER_EMAIL = "webHomeApi/checkUserEmailStatus";
var CHECK_USER_PHONE_NUMBER = "webHomeApi/checkUserPhoneNumberStatus";
var ADD_COMPLAINT_REPLY = "webHomeApi/addComplaintReply";
var SM_SHARE = "webHomeApi/addCronPost";

//Profanity variables
var CHECK_PROFANITY = "webHomeApi/checkProfanity";

export {
  SQUARE_APPLICATION_ID,
  SQUARE_LOCATION_ID,
  ADD_REVIEW,
  ADD_CONTACT_US,
  UPDATE_PROFILE_DATA,
  CUSTOMER_LIST,
  API_URL,
  WEB_URL,
  IMG_URL,
  LOGIN,
  BUSSINESS_CATEGORY,
  SEARCH_BUSSINESS_CATEGORY,
  BUSSINESS_LIST,
  SEARCH_BUSSINESS_LIST,
  CITY_STATE_GET,
  SEARCH_COMPANIES,
  SETTLEMENT_LIST,
  QUESTION_COMPLAINTS,
  ADD_COMPLAINTS,
  COMPLAINTS_LIST,
  PAY_GRADES,
  SERVICE_BRANCHES,
  STATUS,
  AGE_RANGES,
  AUTO_COMPANY_LIST,
  COMPLAINT_TYPE,
  BLOG_LIST,
  BLOG_COMMENTS,
  ADD_BLOG_COMMENTS,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  SIGN_UP,
  GET_BUSINESS_CATEGORY,
  SAVE_PAYMENT_DETAILS,
  MY_PROFILE,
  UPDATE_CONTACT_DETAILS_PROFILE,
  UPDATE_PROFILE_PRODUCT_SERVICES,
  UPDATE_PROFILE_BUSINESS_DETAILS,
  CHANGE_COMPLAINT_STATUS,
  COMPLAINT_DETAILS,
  CHECK_USER_EMAIL,
  CHECK_USER_PHONE_NUMBER,
  ADD_COMPLAINT_REPLY,
  BLOG_RELATED_LIST,
  SM_SHARE,
  CHECK_PROFANITY
};
//END  COMPLAINTS APIS


/* Meta Tags Details/Description */
var TITLE_HOME = "BCR :: Bad Client Reports";
var MTAG_TITLE_HOME = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_HOME = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_HOME = "";
var MTAG_URL_HOME = WEB_URL;

var TITLE_ABOUT_US = "BCR :: About Us";
var MTAG_TITLE_ABOUT_US = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_ABOUT_US = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_ABOUT_US = "";
var MTAG_URL_ABOUT_US = WEB_URL+"aboutus";

var TITLE_CONTACT_US = "BCR :: Contact Us";
var MTAG_TITLE_CONTACT_US = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_CONTACT_US = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_CONTACT_US = "";
var MTAG_URL_CONTACT_US = WEB_URL+"contactus";

var TITLE_BLOG = "BCR :: Blogs";
var MTAG_TITLE_BLOG = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_BLOG = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_BLOG = "";
var MTAG_URL_BLOG = WEB_URL+"blog";

var TITLE_BLOG_DETAILS = "BCR :: Blog Details";
var MTAG_TITLE_BLOG_DETAILS = "BCR: The Sign of a Better Business | Bad Client Reports";

var TITLE_COMPLAINT_DETAILS = "BCR :: Complaint Details";
var MTAG_TITLE_COMPLAINT_DETAILS = "BCR: The Sign of a Better Business | Bad Client Reports";

var TITLE_MY_PROFILE = "BCR :: My Profile";
var MTAG_TITLE_MY_PROFILE = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_MY_PROFILE = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_MY_PROFILE = "";
var MTAG_URL_MY_PROFILE = WEB_URL;

var TITLE_SEARCH = "BCR :: Search Business";
var MTAG_TITLE_SEARCH = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_SEARCH = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_SEARCH = "";
var MTAG_URL_SEARCH = WEB_URL+"search/0/0/0";

var TITLE_DETAILS = "BCR :: Business Details";
var MTAG_TITLE_DETAILS = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_DETAILS = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_DETAILS = "";
var MTAG_URL_DETAILS = WEB_URL;

var TITLE_REVIEWS = "BCR :: Reviews";
var MTAG_TITLE_REVIEWS = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_REVIEWS = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_REVIEWS = "";
var MTAG_URL_REVIEWS = WEB_URL;

var TITLE_REGISTER_COMPLAINT = "BCR :: Register Complaint";
var MTAG_TITLE_REGISTER_COMPLAINT = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_REGISTER_COMPLAINT = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_REGISTER_COMPLAINT = "";
var MTAG_URL_REGISTER_COMPLAINT = WEB_URL;

var TITLE_SIGN_UP = "BCR :: Sign Up";
var MTAG_TITLE_SIGN_UP = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_SIGN_UP = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_SIGN_UP = "";
var MTAG_URL_SIGN_UP = WEB_URL+"signup";

var TITLE_LOGIN = "BCR :: Login";
var MTAG_TITLE_LOGIN = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_LOGIN = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_LOGIN = "";
var MTAG_URL_LOGIN = WEB_URL+"login";

var TITLE_FORGOT_PASSWORD = "BCR :: Forgot Password";
var MTAG_TITLE_FORGOT_PASSWORD = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_FORGOT_PASSWORD = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_FORGOT_PASSWORD = "";
var MTAG_URL_FORGOT_PASSWORD = WEB_URL+"forgotpassword";

var TITLE_CHANGE_PASSWORD = "BCR :: Change Password";
var MTAG_TITLE_CHANGE_PASSWORD = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_CHANGE_PASSWORD = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_CHANGE_PASSWORD = "";
var MTAG_URL_CHANGE_PASSWORD = WEB_URL+"changepassword";

var TITLE_CONTACT_DETAILS = "BCR :: Edit Profile|Contact Details";
var MTAG_TITLE_CONTACT_DETAILS = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_CONTACT_DETAILS = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_CONTACT_DETAILS = "";
var MTAG_URL_CONTACT_DETAILS = WEB_URL;

var TITLE_PRODUCT_SERVICES = "BCR :: Edit Profile|Product & Services";
var MTAG_TITLE_PRODUCT_SERVICES = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_PRODUCT_SERVICES = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_PRODUCT_SERVICES = "";
var MTAG_URL_PRODUCT_SERVICES = WEB_URL;

var TITLE_BUSINESS_DETAILS = "BCR :: Edit Profile|Business Details";
var MTAG_TITLE_BUSINESS_DETAILS = "BCR: The Sign of a Better Business | Bad Client Reports";
var MTAG_DESCRIPTION_BUSINESS_DETAILS = "BCR helps consumers and businesses in the United States and Canada. Find trusted BCR Accredited Businesses. Get BCR Accredited. File a complaint, leave a review, report a scam.";
var MTAG_KEYWORDS_BUSINESS_DETAILS = "";
var MTAG_URL_BUSINESS_DETAILS = WEB_URL;

var TITLE_SQUARE_PAYMENT = "BCR :: Make Payment";
var MTAG_TITLE_SQUARE_PAYMENT = "BCR: The Sign of a Better Business | Bad Client Reports";

export {
  TITLE_HOME,MTAG_TITLE_HOME,MTAG_DESCRIPTION_HOME,MTAG_KEYWORDS_HOME,MTAG_URL_HOME,
  TITLE_ABOUT_US,MTAG_TITLE_ABOUT_US,MTAG_DESCRIPTION_ABOUT_US,MTAG_KEYWORDS_ABOUT_US,MTAG_URL_ABOUT_US,
  TITLE_CONTACT_US,MTAG_TITLE_CONTACT_US,MTAG_DESCRIPTION_CONTACT_US,MTAG_KEYWORDS_CONTACT_US,MTAG_URL_CONTACT_US,
  TITLE_BLOG,MTAG_TITLE_BLOG,MTAG_DESCRIPTION_BLOG,MTAG_KEYWORDS_BLOG,MTAG_URL_BLOG,
  TITLE_BLOG_DETAILS,MTAG_TITLE_BLOG_DETAILS,
  TITLE_COMPLAINT_DETAILS,MTAG_TITLE_COMPLAINT_DETAILS,
  TITLE_MY_PROFILE,MTAG_TITLE_MY_PROFILE,MTAG_DESCRIPTION_MY_PROFILE,MTAG_KEYWORDS_MY_PROFILE,MTAG_URL_MY_PROFILE,
  TITLE_SEARCH,MTAG_TITLE_SEARCH,MTAG_DESCRIPTION_SEARCH,MTAG_KEYWORDS_SEARCH,MTAG_URL_SEARCH,
  TITLE_DETAILS,MTAG_TITLE_DETAILS,MTAG_DESCRIPTION_DETAILS,MTAG_KEYWORDS_DETAILS,MTAG_URL_DETAILS,
  TITLE_REVIEWS,MTAG_TITLE_REVIEWS,MTAG_DESCRIPTION_REVIEWS,MTAG_KEYWORDS_REVIEWS,MTAG_URL_REVIEWS,
  TITLE_REGISTER_COMPLAINT,MTAG_TITLE_REGISTER_COMPLAINT,MTAG_DESCRIPTION_REGISTER_COMPLAINT,MTAG_KEYWORDS_REGISTER_COMPLAINT,MTAG_URL_REGISTER_COMPLAINT,
  TITLE_SIGN_UP,MTAG_TITLE_SIGN_UP,MTAG_DESCRIPTION_SIGN_UP,MTAG_KEYWORDS_SIGN_UP,MTAG_URL_SIGN_UP,
  TITLE_LOGIN,MTAG_TITLE_LOGIN,MTAG_DESCRIPTION_LOGIN,MTAG_KEYWORDS_LOGIN,MTAG_URL_LOGIN,
  TITLE_FORGOT_PASSWORD,MTAG_TITLE_FORGOT_PASSWORD,MTAG_DESCRIPTION_FORGOT_PASSWORD,MTAG_KEYWORDS_FORGOT_PASSWORD,MTAG_URL_FORGOT_PASSWORD,
  TITLE_CHANGE_PASSWORD,MTAG_TITLE_CHANGE_PASSWORD,MTAG_DESCRIPTION_CHANGE_PASSWORD,MTAG_KEYWORDS_CHANGE_PASSWORD,MTAG_URL_CHANGE_PASSWORD,
  TITLE_CONTACT_DETAILS,MTAG_TITLE_CONTACT_DETAILS,MTAG_DESCRIPTION_CONTACT_DETAILS,MTAG_KEYWORDS_CONTACT_DETAILS,MTAG_URL_CONTACT_DETAILS,
  TITLE_PRODUCT_SERVICES,MTAG_TITLE_PRODUCT_SERVICES,MTAG_DESCRIPTION_PRODUCT_SERVICES,MTAG_KEYWORDS_PRODUCT_SERVICES,MTAG_URL_PRODUCT_SERVICES,
  TITLE_BUSINESS_DETAILS,MTAG_TITLE_BUSINESS_DETAILS,MTAG_DESCRIPTION_BUSINESS_DETAILS,MTAG_KEYWORDS_BUSINESS_DETAILS,MTAG_URL_BUSINESS_DETAILS,
  TITLE_SQUARE_PAYMENT,MTAG_TITLE_SQUARE_PAYMENT,

};