import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Footer from "./../Footer/Footer";
import Header from "../Header/Header";
import { API_URL,IMG_URL, BLOG_LIST, MTAG_TITLE_BLOG, MTAG_DESCRIPTION_BLOG, MTAG_KEYWORDS_BLOG, MTAG_URL_BLOG, TITLE_BLOG } from "../../config/Config";
import moment from "moment";
import { Link } from "react-router-dom";
//import Pagination from "./Paginations";
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

const ITEMS_PER_PAGE = 10;

function ContentPaginations() {
  const [userData, setUserData] = useState([]);
  //const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  //React Pagination code
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };
  // Calculate the indices for the current page's data
  const offset = currentPage * ITEMS_PER_PAGE;
  const currentItems = userData.slice(offset, offset + ITEMS_PER_PAGE);

  const [loading, setLoading] = useState(true);
  
  // const APIURL = "https://jsonplaceholder.typicode.com/posts";
  const APIURL = API_URL + BLOG_LIST;
  useEffect(() => {
    fetch(APIURL)
      .then((res) => res.json())
      .then((response) => {
        // console.log(data);
        const data = response.data;
        setUserData(data);
        setLoading(false);
        setTotalPages(Math.ceil(data.length / 2));
      });
  }, [APIURL]);
  //current pages function
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevClick = () => {
    if (currentPage > 20) {
      setCurrentPage(currentPage - 1);
    }
  };
  const preDisabled = currentPage === 1;
  const nextDisabled = currentPage === totalPages;

  const itemsPerPage = 1;
  const startIndex = (currentPage - 10) * itemsPerPage;
  console.log(startIndex);
  //const endIndex = startIndex + itemsPerPage;
  //const itemsToDiaplay = userData.slice(startIndex, endIndex);
  console.log("itemsPerPage", itemsPerPage);


  const [posts, setPosts] = useState([
    {
      userId: 1,
      id: 1,
    },


  ]);
  const [showPerPage, setShowPerPage] = useState(4);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_BLOG}</title>
          <meta name="description" content={MTAG_DESCRIPTION_BLOG} />
          {/* <meta name="keywords" content={MTAG_KEYWORDS_BLOG.join(', ')} /> */}
          <meta property="og:title" content={MTAG_TITLE_BLOG} />
          <meta property="og:description" content={MTAG_DESCRIPTION_BLOG} />
          <meta property="og:url" content={MTAG_URL_BLOG} />
      </Helmet>
      <div className="container py-4">
        
          <div className="wrapData">
            <table className="table_width">
            <tbody>
            {currentItems && currentItems.length > 0
              ? currentItems.map((item, ind) => {
                // const description = (
                //   <div dangerouslySetInnerHTML={{ __html: item.blogContent }} />
                // );
                return (
                  <>
                    <tr key={ind}>
                        <td>
                          <div className="d-flex flex-xl-nowrap flex-xl-nowrap flex-md-nowrap flex-sm-wrap flex-wrap my-2">
                            {item.blogImage !== "" && (
                              <div className="image_col">
                                <img
                                  src={IMG_URL+'blogs/'+item.blogImage}
                                  className="card-img-top img"
                                  alt={""}
                                />
                              </div>
                            )}
                            <div className="content ms-xl-4 ms-lg-4 ms-md-4 ms-sm-0 ms-0 my-2">
                              <div className="d-flex align-items-center">
                                <a
                                  href={"/blog/" + item.blogSlugName}
                                  className="sdeoi"
                                >
                                  <div className="time">{item.blogLabel}</div>
                                </a>
                                <span className="ms-3 text-start">
                                  {" "}
                                  {moment(item.blogCreatedDate).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                              </div>
                              <h2 className="text-starts my-2"><Link
                                to={"/blog/" + item.blogSlugName}
                                className="fw-bold"
                                preventScrollReset={true}
                              >{item.blogTitle}</Link></h2>
                              <p className="text-start my-1">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.blogContent.split("</p>")[0] + "</p>",
                                  }}
                                />
                              </p>

                              <Link
                                to={"/blog/" + item.blogSlugName}
                                className="fw-bold"
                                preventScrollReset={true}
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </td>
                    </tr> 
                  </>
                );
              })
              : ""}
            </tbody>
            </table>

                  {loading === true ? (
                    <h1>Please wait loading ... </h1>
                  ) : userData.length === 0 ? (
                    <h1>We're sorry, we found no results </h1>
                  ) : currentItems && currentItems.length > 0 ? (
                    <>
                      <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(userData.length / ITEMS_PER_PAGE)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                        />
                    </>
                  ):('')}

            
          </div>
          
        
        
      </div>
      <Footer />
    </>
  );
}

export default ContentPaginations;
