import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import logo from "./../img/final 4.png";
import { useParams } from "react-router-dom";
import { API_URL,IMG_URL, MTAG_DESCRIPTION_SEARCH, MTAG_KEYWORDS_SEARCH, MTAG_TITLE_SEARCH, MTAG_URL_SEARCH, SEARCH_COMPANIES, TITLE_SEARCH } from "../config/Config";
import MasterSearch from "./Header/MasterSearch";
import axios from "axios";
import StarRatings from "react-star-ratings";
//import Pagination from "./../MyComponents/Blog/Paginations";
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

//const data = Array.from({ length: 100 }, (_, i) => `Item ${i + 1}`); // Generate sample data

const ITEMS_PER_PAGE = 10;

function Search() {

  const [showPerPage, setShowPerPage] = useState(4);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  /*const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };*/

  const [posts, setPosts] = useState([
    {
      userId: 1,
      id: 1,
    },

  ]);

  let { business, category, city } = useParams();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [businessJson, setBusinessJson] = useState("");
  const [catgoryName, setCategoryName] = useState("");
  const [catgoryJson, setCategoryJson] = useState("");
  const [cityJson, setCityJson] = useState("");
  //const [rating] = useState(2.5);

  //React Pagination code
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };
  // Calculate the indices for the current page's data
  const offset = currentPage * ITEMS_PER_PAGE;
  const currentItems = companyData.slice(offset, offset + ITEMS_PER_PAGE);


  function searchFrm() {
    var productUrl = API_URL + SEARCH_COMPANIES;
    const formData = new FormData();
    formData.append("id", business);
    formData.append("bcId", category);
    formData.append("cityId", city);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setLoading(false);
      setCategoryName(response.data.categoryName);
      setCompanyData(response.data.data);


      if (response.data.categoryJson !== "")
        setCategoryJson(JSON.parse(response.data.categoryJson));
      if (response.data.cityJson !== "")
        setCityJson(JSON.parse(response.data.cityJson));
    });
  }
  useEffect(() => {
    searchFrm();
  }, [business, city, category]);
  return (
    <>
      <Header />
      <Helmet>
        <title>{TITLE_SEARCH}</title>
        <meta name="description" content={MTAG_DESCRIPTION_SEARCH} />
        {/* <meta name="keywords" content={MTAG_KEYWORDS_SEARCH.join(', ')} /> */}
        <meta property="og:title" content={MTAG_TITLE_SEARCH} />
        <meta property="og:description" content={MTAG_DESCRIPTION_SEARCH} />
        <meta property="og:url" content={MTAG_URL_SEARCH} />
      </Helmet>
      <div className="login_col">
        <div className="search_colss">
          <MasterSearch
            setSelectedOptionBusiness={setBusinessJson}
            setSelectedOption={setCategoryJson}
            setCityState={setCityJson}
            selectedOptionBusiness={businessJson}
            selectedOption={catgoryJson}
            cityState={cityJson}
          />
        </div>
        <Container>
          <Row className="py-3">
            <Col xl={8} md={8} lg={8} className="my-2">

              {posts.slice(pagination.start, pagination.end).map((post) => (
                <>
                  {catgoryName !== "" && (
                    <>
                      <h1>Category: {catgoryName}</h1>
                      <p>
                        Showing:{" "}
                        <span className="fw-bold">{companyData.length}</span>{" "}
                        results for <span className="fw-bold">{catgoryName}</span>
                      </p>
                    </>
                  )}
                    <table className="table_width">
                      <tbody>
                        {currentItems.length > 0 &&
                          currentItems.map((res, ind) => {
                            return (
                              <tr key={ind}>
                                <td>
                                  <div className="remodeling mt-2" key={ind}>
                                    <a href={"/details/" + res.cdId+"/"+res.slugTitle}>
                                      <div className="contect_col">
                                        <Row className="align-items-center mb-2">
                                          <Col lg="9">
                                            <h2 className="m-0">{res.cdCompanyName}</h2>
                                            <p className="m-0">
                                              <small className="fw-bold">{res.bcName}</small>
                                            </p>
                                          </Col>
                                          <Col lg="3">
                                            <a href={"/registercomplaint/" + res.cdId}>
                                              File a Complaint
                                            </a>
                                          </Col>
                                        </Row>
                                        {/* <h6 className="fw-bold my-3">BCR Rating: NR</h6> */}
                                        <div className="accredited">
                                          <div className="d-flex align-items-center">
                                            {res.cdImage !== "" && (
                                              <div className="bcr_accrediated">
                                                <div className="bg-white">
                                                  <img
                                                    src={IMG_URL+'users/'+res.cdImage}
                                                    height={50}
                                                    width={50}
                                                    alt=''
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            <div className="contect_col ms-3">
                                              <StarRatings
                                                rating={parseFloat(res.ratings)}
                                                starRatedColor="#FFD700"
                                                numberOfStars={5}
                                                name="rating"
                                                starDimension="20px"
                                                starSpacing="2px"
                                              />
                                              <p>
                                                <small>
                                                  {res.cdAddress !== ""
                                                    ? res.cdAddress + ","
                                                    : ""}
                                                  {res.cityName}, {res.regionCode}
                                                  {res.cdPinCode !== ""
                                                    ? "," + res.cdPinCode
                                                    : ""}
                                                </small>
                                                <br></br>
                                                <small>
                                                  Complaint :{res.resolvedComplaint}{" "}
                                                  <i
                                                    className="fa fa-check text-success"
                                                    title="Resolved"
                                                  ></i>{" "}
                                                  {res.pendingComplaint}{" "}
                                                  <i
                                                    className="fa fa-clock text-danger"
                                                    title="Pendings"
                                                  ></i>
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </td>
                            </tr> 
                            );
                          })}
                        </tbody>
                      </table>
                      
                  {loading === true ? (
                    <h1>Please wait searching ... </h1>
                  ) : companyData.length === 0 ? (
                    <h1>We're sorry, we found no results </h1>
                  ) : (
                    <>
                      <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(companyData.length / ITEMS_PER_PAGE)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                        />
                    </>
                  )}
                  
                </>

              ))}






              {/* <div className="form form_cols">
                <h6 className="fw-bold filters">Filter by</h6>
                <div className="d-flex align-items-center">
                  <Form className="sdert w-50">
                    {["checkbox"].map((type) => (
                      <div key={`default-${type}`}>
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Serving my area`}
                        />
                      </div>
                    ))}
                  </Form>
                  <Form.Select
                    aria-label="Default select example"
                    className="select_col w-25 mx-3"
                  >
                    <option>Get Connected</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                  <Form.Select
                    aria-label="Default select example"
                    className="select_col w-25 "
                  >
                    <option>Categories</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                  <Form.Select
                    aria-label="Default select example"
                    className="select_col w-25 mx-3"
                  >
                    <option>BCR Rating</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
                <div className="d-flex align-items-center my-3">
                  <Form.Select
                    aria-label="Default select example"
                    className="select_col w-25 "
                  >
                    <option>State/Province</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                  <Form.Select
                    aria-label="Default select example"
                    className="select_col w-25 mx-3"
                  >
                    <option>Show Only</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </div> */}

            </Col>
            <Col xl={4} md={4} lg={4} className="my-2">
              <Card>
                <Card.Body>
                  <Card.Text>
                    <span className="text_colss fw-bold">
                      Related Categories
                    </span>
                  </Card.Text>
                  <div className="categories_col">
                    <a href="dfd">
                      <h6>Construction Services</h6>
                    </a>
                    <a href="dfd">
                      <h6>General Contractor</h6>
                    </a>
                    <a href="dfd">
                      <h6>Bathroom Remodel</h6>
                    </a>
                    <a href="dfd">
                      <h6>Kitchen Remodel</h6>
                    </a>
                    <a href="dfd">
                      <h6>Siding Contractors</h6>
                    </a>
                    <a href="dfd">
                      <h6>Roofing Contractors</h6>
                    </a>
                    <a href="dfd">
                      <h6>Remodeling</h6>
                    </a>
                    <a href="dfd">
                      <h6>Remodel Contractors</h6>
                    </a>
                    <a href="dfd">
                      <h6>Home Additions</h6>
                    </a>
                    <a href="dfd">
                      <h6>Bathroom Design</h6>
                    </a>
                  </div>
                </Card.Body>
              </Card>
              <Card className="my-3">
                <Card.Body>
                  <p></p>
                  <Card.Text>
                    <span className="text_col fw-bold">
                      Accreditation and Ratings Overview
                    </span>
                  </Card.Text>
                  <div className="d-flex flex-wrap dffrtr">
                    <div className="image_cols me-2 my-2">
                      <img src={logo} className="img" alt={"working"} />
                    </div>
                    <p className="my-2">
                      Select businesses earn BCR Accreditation by undergoing a
                      thorough evaluation and upholding the BCR Accreditation
                      Standards.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap my-2 dffrtr">
                    <div className="image_cols my-2">
                      <span className="text-blue-brand me-3">
                        <span className="font-bold">A</span>+
                      </span>
                    </div>
                    <p className="my-2">
                      BCR assigns ratings from A+ (highest) to F (lowest). In
                      some cases, BCR will not rate the business (indicated by
                      an NR, or "No Rating") for reasons that include
                      insufficient information about a business or ongoing
                      review/update of the business's file.
                    </p>
                  </div>
                </Card.Body>
              </Card>

              <Card className="my-3">
                <Card.Body>
                  <div className="dffrtr">
                    <div className="image_cols">
                      <span className="fertrgyt">
                        Didn't find the business you were looking for?
                      </span>
                    </div>
                    <p>
                      If the business you're looking for isn't in our directory,
                      submit a request to have it added.
                    </p>
                  </div>
                  <Button variant="primary">Request a Business</Button>{" "}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Search;
