import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
//import { getLocalData } from "../../config/localStrorage";
import {
  API_URL,
  SEARCH_BUSSINESS_CATEGORY,SEARCH_BUSSINESS_LIST,
  CITY_STATE_GET,
} from "../../config/Config";

// Sample data with id and value
const items = [
  { id: 1, value: 'Apple' },
  { id: 2, value: 'Banana' },
  { id: 3, value: 'Orange' },
  { id: 4, value: 'Mango' },
  { id: 5, value: 'Pineapple' },
  { id: 6, value: 'Strawberry' },
  { id: 7, value: 'Blueberry' },
  { id: 8, value: 'Grapes' },
  { id: 9, value: 'Watermelon' },
];

function MasterSearch({
  selectedOption,
  setSelectedOption,
  selectedOptionBusiness,
  setSelectedOptionBusiness,
  cityState,
  setCityState,
}) {
  let navigate = useNavigate();
  const [busCateType, setBusCateData] = useState([]);
  const [businessListData, setBusinessListData] = useState([]);

  

  const handleChangeBusiness = (selected) => {
    setSelectedOptionBusiness(selected);
  };

  //console.log(cityState, selectedOption);
  // useEffect(() => {
  //   fetchcTypeData();
  //   //fetchcCompanyData();
  // }, []);


  //city,state data
  const customControlStyles = (base) => ({ ...base, fontSize: 14 });
  const promiseCityStateOptions = (sstring) =>
    new Promise((resolve, reject) => {
      fetch(`${API_URL + CITY_STATE_GET}?q=${sstring}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            var filtered = result.data.map((d) => ({
              value: d.id,
              label: d.name,
              type: d.type,
            }));
            resolve(filtered);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });
  const handleChanges = (selectedOption) => {
    setCityState(selectedOption);
  };
  function searchFrm() {
    let business = 0;
    let category = 0;
    let city = 0;
    console.log("citystate", selectedOption);
    if (selectedOptionBusiness?.value) business = selectedOptionBusiness.value;
    if (selectedOption?.value) category = selectedOption.value;
    if (cityState?.value) city = cityState.value;
    navigate("/search/" + queryBusinessId + "/" + queryCategoryId + "/" + city);
  }

  //---- New code ---------------
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('0');
  const [queryBusiness, setQueryBusiness] = useState('');
  const [queryBusinessId, setQueryBusinessId] = useState('0');
  const [suggestionsBusiness, setSuggestionsBusiness] = useState([]);
  const [selectedItemBusiness, setSelectedItemBusiness] = useState(null);

  const [queryCategory, setQueryCategory] = useState('');
  const [queryCategoryId, setQueryCategoryId] = useState('0');
  const [suggestionsCategory, setSuggestionsCategory] = useState([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);

  useEffect(() => {

    setSuggestionsBusiness([]);
    setSuggestionsCategory([]);

    if (searchType==='1' && queryBusiness.length > 2 && loading === true) {
      const fetchcCompanyData = async () => {
        var productUrl = API_URL + SEARCH_BUSSINESS_LIST;
        var formData = new FormData();
        formData.append("query", queryBusiness);
        axios({
          method: "post",
          url: productUrl,
          data: formData,
        }).then(function (response) {
          setSuggestionsBusiness(response.data.data);
        });
      };
      fetchcCompanyData();
    } else {
      setSuggestionsBusiness([]);
    }

    if (searchType==='2' && queryCategory.length > 2 && loading === true) {
      const fetchcTypeData = async () => {
        var productUrl = API_URL + SEARCH_BUSSINESS_CATEGORY;
        var formData = new FormData();
        formData.append("query", queryCategory);
        axios({
          method: "post",
          url: productUrl,
          data: formData,
        }).then(function (response) {
          setSuggestionsCategory(response.data.data);
        });
      };
      fetchcTypeData();
    } else {
      setSuggestionsCategory([]);
    }

  }, [queryBusiness,queryCategory]);
  
  const handleChange = (event) => {
    const value = event.target.value;
    setQueryBusinessId('0');
    setQueryBusiness(value);
    setSearchType('1');
    setLoading(true);
  };

  const handleSuggestionClick = (item) => {
    setQueryBusiness(item.value);
    setQueryBusinessId(item.id);
    setSelectedItemBusiness(item);
    setSuggestionsBusiness([]);
    setLoading(false);
  };

  const handleChangeCategory = (event) => {
    const value = event.target.value;
    setQueryCategoryId('0');
    setQueryCategory(value);
    setSearchType('2');
    setLoading(true);
  };

  const handleSuggestionCategoryClick = (item) => {
    setQueryCategory(item.value);
    setQueryCategoryId(item.id);
    setSelectedItemCategory(item);
    setSuggestionsCategory([]);
    setLoading(false);
  };
  //--- end code
  return (
    <div className="bg-blue-medium css-1dlghxq e19go38n1">
      <form id="search-form">
        <label htmlFor=":R6lal4pa:" aria-hidden="true" className="visually-hidden">
          Honeypot
          <input
            id=":R6lal4pa:"
            type="radio"
            name="honeypot"
            tabIndex="-1"
            value="1"
          />
        </label>
        <div className="css-1xdgpde epsahlr0">

        <div className="dtm-header-near-typeahead e1wzch7s0 css-wmunhi e19i3r7f0">
            <label className="p-2">Find</label>
            <input
              type="text" className="home-search"
              value={queryBusiness}
              onChange={handleChange}
              placeholder="Search Business ..."
            />
            {suggestionsBusiness.length > 0 && searchType==='1' && (
              <ul className="suggestions-list">
                {suggestionsBusiness.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleSuggestionClick(item)}
                    className="suggestion-item"
                  >
                    {item.value}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="dtm-header-near-typeahead e1wzch7s0 css-wmunhi e19i3r7f0">
            <label className="p-2">Category</label>
            <input
              type="text" className="home-search"
              value={queryCategory}
              onChange={handleChangeCategory}
              placeholder="Search Category ..."
            />
            {suggestionsCategory.length > 0 && searchType==='2' && (
              <ul className="suggestions-list-cat">
                {suggestionsCategory.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleSuggestionCategoryClick(item)}
                    className="suggestion-item"
                  >
                    {item.value}
                  </li>
                ))}
              </ul>
            )}
            
          </div>
          <div className="dtm-header-near-typeahead e1wzch7s0 css-wmunhi e19i3r7f0">
            <label
              htmlFor=":Rlalal4pa:"
              id=":Rlalal4paH1:"
              className="eki464u0 css-1nm0rrn ete3jmq0"
            >
              Near
            </label>
            
            <AsyncSelect
              onChange={handleChanges}
              value={cityState}
              cacheOptions
              placeholder="City, State"
              name="hdCity"
              defaultOptions
              loadOptions={promiseCityStateOptions}
              styles={{
                control: customControlStyles,
                option: customControlStyles,
              }}
            />
          </div>

          <button
            className="bds-button dtm-header-search-submit btn-warning css-wbkz77 mt-2 e19go38n0"
            type="button"
            onClick={() => searchFrm()}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
export default MasterSearch;
