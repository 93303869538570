import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
//import { getLocalData } from "../../config/localStrorage";
import {
  API_URL,
  BUSSINESS_CATEGORY,BUSSINESS_LIST,
  CITY_STATE_GET,
} from "../../config/Config";
function SearchCompany({
  selectedOption,
  setSelectedOption,
  selectedOptionBusiness,
  setSelectedOptionBusiness,
  cityState,
  setCityState,
}) {
  let navigate = useNavigate();
  const [busCateType, setBusCateData] = useState([]);
  const [businessListData, setBusinessListData] = useState([]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleChangeBusiness = (selected) => {
    setSelectedOptionBusiness(selected);
  };

  //console.log(cityState, selectedOption);
  useEffect(() => {
    fetchcTypeData();
    fetchcCompanyData();
  }, []);
  const fetchcCompanyData = () => {
    var productUrl = API_URL + BUSSINESS_LIST;
    var formData = new FormData();
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setBusinessListData(response.data.data);
    });
  };

  const fetchcTypeData = () => {
    var productUrl = API_URL + BUSSINESS_CATEGORY;
    var formData = new FormData();
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setBusCateData(response.data.data);
    });
  };

  //city,state data
  const customControlStyles = (base) => ({ ...base, fontSize: 14 });
  const promiseCityStateOptions = (sstring) =>
    new Promise((resolve, reject) => {
      fetch(`${API_URL + CITY_STATE_GET}?q=${sstring}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            var filtered = result.data.map((d) => ({
              value: d.id,
              label: d.name,
              type: d.type,
            }));
            resolve(filtered);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });
  const handleChanges = (selectedOption) => {
    setCityState(selectedOption);
  };
  function searchFrm() {
    let business = 0;
    let category = 0;
    let city = 0;
    console.log("citystate", selectedOption);
    if (selectedOptionBusiness?.value) business = selectedOptionBusiness.value;
    if (selectedOption?.value) category = selectedOption.value;
    if (cityState?.value) city = cityState.value;
    navigate("/search/" + business + "/" + category + "/" + city);
  }
  return (
    <div className="bg-blue-medium css-1dlghxq e19go38n1">
      <form id="search-form">
        <label htmlFor=":R6lal4pa:" aria-hidden="true" className="visually-hidden">
          Honeypot
          <input
            id=":R6lal4pa:"
            type="radio"
            name="honeypot"
            tabIndex="-1"
            value="1"
          />
        </label>
        <div className="css-1xdgpde epsahlr0">

        <div className="dtm-header-find-typeahead e1wzch7s0 css-wmunhi e19i3r7f0">
            <label className="p-2">Find</label>
            <Select
              className="form-control border-0"
              value={selectedOptionBusiness}
              onChange={handleChangeBusiness}
              options={businessListData}
              isClearable={true} // Optional: allows clearing the selection
            />
            {/* <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>businesses, category</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> */}
          </div>
          <div className="dtm-header-find-typeahead e1wzch7s0 css-wmunhi e19i3r7f0">
            <label className="p-2">Category</label>
            <Select
              className="form-control border-0"
              value={selectedOption}
              onChange={handleChange}
              options={busCateType}
              isClearable={true} // Optional: allows clearing the selection
            />
            {/* <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>businesses, category</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> */}
          </div>
          <div className="dtm-header-near-typeahead e1wzch7s0 css-wmunhi e19i3r7f0">
            <label
              htmlFor=":Rlalal4pa:"
              id=":Rlalal4paH1:"
              className="eki464u0 css-1nm0rrn ete3jmq0"
            >
              Near
            </label>
            {/* <div className="css-1ci0kms etyu6mh0">
                <input
                  aria-autocomplete="list"
                  autocomplete="off"
                  id=":Rlalal4pa:"
                  autocapitalize="none"
                  name="find_loc"
                  placeholder="city, state or zip"
                  role="combobox"
                  aria-expanded="false"
                  aria-owns=":Rlalal4paH2:"
                  className="css-d0ontt eyrwz0y0"
                  value=""
                />
                <div
                  role="listbox"
                  aria-labelledby=":Rlalal4paH1:"
                  id=":Rlalal4paH2:"
                  className="css-14232aq ekqkvz0"
                >
                  <ul className="css-1uunpja ekqkvz3"></ul>
                </div>
              </div> */}
            <AsyncSelect
              onChange={handleChanges}
              value={cityState}
              cacheOptions
              placeholder="City, State"
              name="hdCity"
              defaultOptions
              loadOptions={promiseCityStateOptions}
              styles={{
                control: customControlStyles,
                option: customControlStyles,
              }}
            />
          </div>

          <button
            className="bds-button dtm-header-search-submit btn-warning css-wbkz77 mt-2 e19go38n0"
            type="button"
            onClick={() => searchFrm()}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
export default SearchCompany;
