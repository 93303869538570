import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLocalData } from "../config/localStrorage";
import { API_URL, CHANGE_PASSWORD, MTAG_DESCRIPTION_CHANGE_PASSWORD, MTAG_KEYWORDS_CHANGE_PASSWORD, MTAG_TITLE_CHANGE_PASSWORD, MTAG_URL_CHANGE_PASSWORD, TITLE_CHANGE_PASSWORD } from "../config/Config";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

function ChangePassword() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const local = getLocalData();
    function frmSubmit() {
        if (oldPassword === "") {
            toast.error("Please enter old password");
            return;
        }
        if (newPassword === "") {
            toast.error("Please enter new password");
            return;
        }
        if (confirmPassword === "") {
            toast.error("Please enter confirm password");
            return;
        }
        if (newPassword !== confirmPassword) {
            toast.error("New password and confirm password does not match");
            return;
        }
        var productUrl = API_URL + CHANGE_PASSWORD;
        const formData = new FormData();
        formData.append("oldPassword", oldPassword);
        formData.append("newPassword", newPassword);
        formData.append("userId", local.userId);
        axios({
            method: "post",
            url: productUrl,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: local.token,
            }
        }).then(function (response) {
            let msg = response.data.msg;
            if (response.data.status === true) {
                toast.success(msg);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                toast.error(msg);
            }
        });
    }
    return (
        <>
            <Header />
            <Helmet>
                <title>{TITLE_CHANGE_PASSWORD}</title>
                <meta name="description" content={MTAG_DESCRIPTION_CHANGE_PASSWORD} />
                {/* <meta name="keywords" content={MTAG_KEYWORDS_CHANGE_PASSWORD.join(', ')} /> */}
                <meta property="og:title" content={MTAG_TITLE_CHANGE_PASSWORD} />
                <meta property="og:description" content={MTAG_DESCRIPTION_CHANGE_PASSWORD} />
                <meta property="og:url" content={MTAG_URL_CHANGE_PASSWORD} />
            </Helmet>
            <div className="login_col">
                <Container className="ghdtsrers">
                    <Row className="align-items-center justify-content-center h-100">
                        <Col xl={6} md={12} lg={6}>
                            <div className="login_colss p-3">
                                <h2 className='text-center touch m-0 py-3'>Change Password</h2>
                                <Form.Group className="mb-2 p-1" controlId="formGroupEmail">
                                    <Form.Label>Old Password<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter old password"
                                        onChange={(e) => setOldPassword(e.target.value)}                                        
                                    />                                    
                                </Form.Group>
                                <Form.Group className="mb-2 p-1" controlId="formGroupEmail">
                                    <Form.Label>New Password<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter new password"  
                                        onChange={(e) => setNewPassword(e.target.value)}                                          
                                    />                                    
                                </Form.Group>
                                <Form.Group className="mb-2 p-1" controlId="formGroupEmail">
                                    <Form.Label>Confirm Password<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter confirm password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </Form.Group>
                                <div className="login_col text-end">
                                    <Button
                                        className="button_cols my-4"
                                        type="button"
                                        onClick={frmSubmit}
                                    >
                                        Submit
                                    </Button>{" "}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
            <Footer />
        </>
    );
}

export default ChangePassword;
