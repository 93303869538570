import React, { useState, useEffect, Fragment } from "react";
import { Card, InputGroup, Form } from "react-bootstrap";
import axios from "axios";
import {
  API_URL,
  SETTLEMENT_LIST,
  QUESTION_COMPLAINTS,
  ADD_COMPLAINTS,
  PAY_GRADES,
  SERVICE_BRANCHES,
  STATUS,
  AGE_RANGES,
  AUTO_COMPANY_LIST,
  COMPLAINT_TYPE,
} from "../../config/Config";
import { getLocalData } from "../../config/localStrorage";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function AddComplaints() {
  const [settlementData, setSettlementData] = useState([]);
  const [quesData, setQuesData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [stlId, setStlId] = useState(0);
  const [isUs, setIsUs] = useState(0);
  const [statusData, setStatusData] = useState([]);
  const [payGradesData, setPayGradesData] = useState([]);
  const [serviceBranchesData, setServiceBranchesData] = useState([]);
  // const [sId, setSId] = useState(0);
  // const [sbId, setSbId] = useState(0);
  // const [pgId, setPgId] = useState(0);
  const [adQues, setAdQues] = useState(0);
  const [ageRangeData, setAgeRangeData] = useState([]);
  const [arId, setArId] = useState(0);
  // const router = useHistory();
  var local = getLocalData();

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const fetchSettlementData = () => {
    var productUrl = API_URL + SETTLEMENT_LIST;
    axios.get(productUrl).then((response) => {
      setSettlementData(response.data.data);
    });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  const fetchQuestionData = () => {
    var productUrl = API_URL + QUESTION_COMPLAINTS;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setQuesData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  const fetchStatusData = () => {
    var productUrl = API_URL + STATUS;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setStatusData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  const fetchBranchServicesData = () => {
    var productUrl = API_URL + SERVICE_BRANCHES;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setServiceBranchesData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  const fetchPayGradesData = () => {
    var productUrl = API_URL + PAY_GRADES;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setPayGradesData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  const fetchAgeRangeData = () => {
    var productUrl = API_URL + AGE_RANGES;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setAgeRangeData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  const [compType, setCompTypeData] = useState([]);
  const [ctId, setCtId] = useState(0);
  const fetchcTypeData = () => {
    var productUrl = API_URL + COMPLAINT_TYPE;
    axios
      .get(productUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        // console.log(response);
        setCompTypeData(response.data.data);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  useEffect(() => {
    fetchSettlementData();
    fetchQuestionData();
    fetchcTypeData();
    getAutoCompanyList();
  }, []);

  const [files, setFiles] = useState([]);

  const renderFileList = () => (
    <ul>
      {[...files].map((f, i) => (
        <li key={i}>
          <b>
            <div className="text-white css-rs23t5 ek0ml8b0">{f.type}</div>
            {f.name}
          </b>
        </li>
      ))}
    </ul>
  );

  const submitFrm = () => {
    var productUrl = API_URL + ADD_COMPLAINTS;
    const formData = new FormData(document.getElementById("complaintFrm"));

    if (ctId === 4) {
      if (title === "") {
        toast.error("Please Fill Title");
        return;
      }
    }

    if (description === "") {
      toast.error("Please Fill Description");
      return;
    }
    if (stlId === "") {
      toast.error("Please Fill Desired Sattlement");
      return;
    }
    if (adQues === 1 && arId === 0) {
      toast.error("Please select age range");
      return;
    }
    if (
      formData.get("ans[11]") == null ||
      formData.get("ans[12]") == null ||
      formData.get("ans[13]") == null
    ) {
      toast.error("Please select terms and conditions");
      return;
    }
    formData.append("userId", local.customerId);
    formData.append("isUs", isUs);
    formData.append("additionalQuestions", adQues);
    if (title === "") {
      formData.append("title", "");
    }
    if (files.length > 0) {
      [...files].forEach((file, i) => {
        formData.append(`file-${i}`, file, file.name);
      });
    }
    if (selectedOption != null) {
      formData.append("companyId", selectedOption.value);
    } else {
      formData.append("companyId", 0);
    }
    // console.log(local);
    // console.log(Array.from(formData));
    // return;
    axios
      .post(productUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: local.token,
        },
      })
      .then((response) => {
        setTitle("");
        setDescription("");
        setStlId(0);
        toast.success(response.data.msg);
        setTimeout(() => {
          // router.push("complaintList");
        }, 1000);
      });
    // .catch((error) => {
    //   redirectToErrorPage(error.message, router);
    // });
  };
  //------ Start auto complete functions ----
  const [suggestions, setSuggestions] = useState([]);
  const getAutoCompanyList = async () => {
    var apiUrl = API_URL + AUTO_COMPANY_LIST;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: local.token,
        },
      })
      .then((response) => {
        setSuggestions(response.data.data);
        console.log(response.data);
      });
  };

  return (
    <>
      <Header />
      <Card>
        <Card.Header className="rvtd d-flex justify-content-between">
          Add Complaint
        </Card.Header>
        <Card.Body className="m-5">
          <form id="complaintFrm">
            <InputGroup className="my-2">
              <label>Company</label>
              <div id="autosuggest">
                <Select
                  value={selectedOption}
                  onChange={handleChange}
                  options={suggestions}
                  isClearable={true} // Optional: allows clearing the selection
                />
              </div>
            </InputGroup>
            <InputGroup>
              <label>Complaint Type</label>
              <select
                size="lg"
                className="form-control"
                name="ctId"
                onChange={(e) => setCtId(e.target.value)}
              >
                <option key={0} value={0}>
                  Please Select
                </option>
                {compType.length > 0 &&
                  compType.map((res) => {
                    return (
                      <option key={res.ctId} value={res.ctId}>
                        {res.ctName}
                      </option>
                    );
                  })}
              </select>
            </InputGroup>
            {ctId === 4 && (
              <>
                <InputGroup>
                  <label>Other :</label>
                  <input
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </InputGroup>
              </>
            )}
            <InputGroup>
              <label>Complaint Description</label>
              <textarea
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              ></textarea>
            </InputGroup>
            <InputGroup>
              <label>Desired Sattlement</label>
              <select
                className="form-control"
                name="stlId"
                onChange={(e) => setStlId(e.target.value)}
                value={stlId}
              >
                <option value={0} key={0}>
                  Please select
                </option>
                {settlementData.length > 0 &&
                  settlementData.map((res) => {
                    return (
                      <option value={res.stlId} key={res.stlId}>
                        {res.stlName}
                      </option>
                    );
                  })}
              </select>
            </InputGroup>
            <InputGroup className={"p-3"}>
              <label>
                <Form.Check
                  key={"isus"}
                  name="cIsUs"
                  value={isUs === 1 ? 0 : 1}
                  onChange={(e) => {
                    setIsUs(e.target.value);
                    fetchStatusData();
                    fetchPayGradesData();
                    fetchBranchServicesData();
                  }}
                />
                (Optional) I am a US active duty service member, a civilian
                employee of the Department of Defense, a military dependent, a
                veteran, or retired from active duty.
              </label>
            </InputGroup>
            {isUs === 1 && (
              <>
                <InputGroup>
                  <label>
                    <b>Status (optional)</b>
                  </label>
                  <select
                    className="form-control"
                    name="sId"
                    // onChange={(e) => setSId(e.target.value)}
                  >
                    <option key={0} value={0}>
                      Select a status
                    </option>
                    {statusData.length > 0 &&
                      statusData.map((res) => {
                        return (
                          <option key={res.sId} value={res.sId}>
                            {res.sName}
                          </option>
                        );
                      })}
                  </select>
                </InputGroup>
                <InputGroup>
                  <label>
                    <b>Branch of service (optional)</b>
                  </label>
                  <select
                    className="form-control"
                    name="sbId"
                    // onChange={(e) => setSbId(e.target.value)}
                  >
                    <option key={0} value={0}>
                      Select a branch of service
                    </option>
                    {serviceBranchesData.length > 0 &&
                      serviceBranchesData.map((res) => {
                        return (
                          <option key={res.sbId} value={res.sbId}>
                            {res.sbName}
                          </option>
                        );
                      })}
                  </select>
                </InputGroup>
                <InputGroup>
                  <label>
                    <b>Pay grade (optional)</b>
                  </label>
                  <select
                    className="form-control"
                    name="pgId"
                    // onChange={(e) => setPgId(e.target.value)}
                  >
                    <option key={0} value={0}>
                      Select a pay grade
                    </option>
                    {payGradesData.length > 0 &&
                      payGradesData.map((res) => {
                        return (
                          <option key={res.pgId} value={res.pgId}>
                            {res.pgName}
                          </option>
                        );
                      })}
                  </select>
                </InputGroup>
              </>
            )}
            <InputGroup className={"p-3"}>
              <label>
                <Form.Check
                  key={"cAdditionalQuestions"}
                  name="cAdditionalQuestions"
                  value={adQues === 1 ? 0 : 1}
                  onChange={(e) => {
                    setAdQues(e.target.value);
                    fetchAgeRangeData();
                  }}
                />
                Would you be willing to help BCR with our complaint reporting by
                answering some additional questions?
              </label>
            </InputGroup>
            {adQues === 1 && (
              <>
                <InputGroup>
                  <label>
                    <b>What is your age range?</b>
                  </label>
                  <select
                    className="form-control"
                    name="arId"
                    onChange={(e) => setArId(e.target.value)}
                  >
                    <option key={0} value={0}>
                      Select age range
                    </option>
                    {ageRangeData.length > 0 &&
                      ageRangeData.map((res) => {
                        return (
                          <option key={res.arId} value={res.arId}>
                            {res.aRange}
                          </option>
                        );
                      })}
                  </select>
                </InputGroup>
                <InputGroup>
                  <label>
                    I am willing to discuss what I know with the news media.
                  </label>
                  <select name="cWillingDiscuss" className="form-control">
                    <option key="" value="">
                      Select any answer
                    </option>
                    <option key="1" value="1">
                      Yes
                    </option>
                    <option key="0" value="0">
                      No
                    </option>
                  </select>
                </InputGroup>
              </>
            )}
            <row className={"p-3"}>
              {quesData.length > 0 &&
                quesData.map((res, indexKey) => {
                  return (
                    <Fragment key={indexKey}>
                      <InputGroup>
                        <label>
                          {res.cqQuestionType === "5" && (
                            <>
                              <Form.Check name={"ans[" + res.cqId + "]"} />
                            </>
                          )}

                          {res.cqQuestion}
                        </label>
                      </InputGroup>
                      <InputGroup>
                        {res.cqQuestionType === "1" && (
                          <input name={"ans[" + res.cqId + "]"} type="number" />
                        )}
                        {res.cqQuestionType === "2" && (
                          <>
                            <input
                              type="radio"
                              name={"ans[" + res.cqId + "]"}
                              value={"yes"}
                            />
                            {"  "}
                            Yes{" "}
                            <input
                              type="radio"
                              name={"ans[" + res.cqId + "]"}
                              value="no"
                            />{" "}
                            No
                          </>
                        )}
                      </InputGroup>
                    </Fragment>
                  );
                })}
            </row>
            <InputGroup>
              <label>
                <b>Upload supporting documents (optional)</b>
                <p>
                  Supporting documents submitted will only be shared with the
                  business for the purpose of resolving your complaint. Example:
                  receipts, contracts, pictures, advertisements, etc.
                </p>
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
              {renderFileList()}
            </InputGroup>
            <button
              type="button"
              onClick={() => {
                submitFrm();
              }}
              className="btn btn-success"
            >
              {" "}
              Save
            </button>
          </form>
          <ToastContainer />
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}
