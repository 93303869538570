import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Container, Card, Row, Col, Form } from 'react-bootstrap';
import { API_URL, ADD_CONTACT_US, MTAG_TITLE_CONTACT_US, MTAG_DESCRIPTION_CONTACT_US, MTAG_KEYWORDS_CONTACT_US, MTAG_URL_CONTACT_US, TITLE_CONTACT_US } from "../config/Config";
import axios from "axios";
import { getLocalData } from "../config/localStrorage";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from 'react-helmet';

function ContactUs() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    
    const navigate = useNavigate();
    const local = getLocalData();
    function addContactUs() {
        var productUrl = API_URL + ADD_CONTACT_US;
        const formData = new FormData();
    
        if (name === "") {
          toast.error("Please enter your name");
          return;
        }
        if (email === "") {
          toast.error("Please enter your email");
          return;
        }
        if (description === "") {
            toast.error("Please enter description");
            return;
        }
        
        formData.append("reviewUserId", local.userId);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("description", description);
        axios({
          method: "post",
          url: productUrl,
          data: formData,
        }).then(function (response) {
          const msg = response.data.msg;
          if (response.data.status === true) {
            toast.success(msg);
            navigate("/contactus");
            setName("");
            setEmail("");
            setDescription("");
          } else {
            toast.error(msg);
          }
        });
      }

    return (
        <>
            <Header />
            <Helmet>
                <title>{TITLE_CONTACT_US}</title>
                <meta name="description" content={MTAG_DESCRIPTION_CONTACT_US} />
                {/* <meta name="keywords" content={MTAG_KEYWORDS_CONTACT_US.join(', ')} /> */}
                <meta property="og:title" content={MTAG_TITLE_CONTACT_US} />
                <meta property="og:description" content={MTAG_DESCRIPTION_CONTACT_US} />
                <meta property="og:url" content={MTAG_URL_CONTACT_US} />
            </Helmet>
            <Container className="about_col d-xl-block d-lg-block d-md-block d-sm-none d-none">
                <Row className='align-items-center justify-content-center h-100'>
                    <Col lg="10">
                        <Card className="my-3">
                            <Card.Body>
                                <Row>
                                    <Col lg="6" sm="12" className='my-2'>
                                        <div className="contactss_colss">
                                            <h2 className='touch fw-bold'>Contact Us</h2>
                                            <p className='font_family'>We are here for you! How can we help?</p>
                                            <Form>
                                                <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
                                                Your Name<span style={{ color: "red" }}>*</span> <Form.Control type="text" placeholder="Enter your name" onChange={(e) => setName(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
                                                Email<span style={{ color: "red" }}>*</span> <Form.Control type="email" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                                                Description<span style={{ color: "red" }}>*</span> <Form.Control as="textarea" rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                                                </Form.Group>
                                                <button
                                                    type="button" onClick={addContactUs}
                                                    className="button_colss w-100 my-2 text-white btn px-4 rounded-0 font_family"
                                                >
                                                    Submit
                                                </button>
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col lg="6" sm="12" className='my-2'>
                                        <div className="images_colss text-center">
                                            <img src={require("./../img/contact.jpg")} className="card-img-top imgss_colss" alt={"working"} />
                                        </div>
                                        <div className="d-flex align-items-baseline mt-2">
                                            <i className="fas fa-map-marker"></i>
                                            <span className="ms-2 fgfdhgt font_family">
                                                PO Box 218<br></br>
                                                San Leandro, CA 94577<br></br>
                                            </span>
                                        </div>

                                        <div className="d-flex align-items-baseline my-4">
                                            <a href="fdg">
                                                <i className="fas fa-envelope"></i>
                                                <span className="ms-2 fgfdhgt font_family">Email this BCR</span>
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-baseline">
                                            <i className="fas fa-phone-alt"></i>
                                            <div>
                                                <span className="ms-2 fgfdhgt font_family">Ph:(866) 411-2221</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
            <ToastContainer />
            <Footer />
        </>
    )
}

export default ContactUs;