import React from 'react'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Container} from 'react-bootstrap';
function BenefitCustomer() {
    return (
        <>
            <Header />
            <Container className="py-3 ghdtsrers">
                <h1 className='my-3'>Coming Soon....</h1>
            </Container>
            <Footer />
        </>
    )
}

export default BenefitCustomer;