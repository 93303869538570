import React, { } from "react";
import { Container, Row, Col, Card,  Navbar, Nav, Form, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Footer from "./Footer/Footer";

function Payments() {

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary p-1">
                <Container>
                    <Navbar.Brand href="/" className="d-flex">
                        <div className="image">
                            <img src={require("./../img/final 4.png")} className="img" alt={"working"} />
                            <span className="reports ms-3">Bad Client Reports</span>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="me-3">
                        <Nav className="m-auto">
                            <ul>
                                <li className="dfertyuio">
                                    <i className="fa-solid fa-address-card"></i>
                                    <br></br>
                                    <NavLink to="/aboutus">About Us</NavLink>
                                </li>

                                <li className="dfertyuio">
                                    <i className="fa-solid fa-address-book"></i>
                                    <br></br>
                                    <NavLink to="/contactus">Contact Us</NavLink>
                                </li>

                                <li className="dfertyuio">
                                    <i className="fa-solid fa-blog"></i>
                                    <br></br>
                                    <NavLink to="/blog">Blogs</NavLink>
                                </li>

                                <li className="dfertyuio">
                                    <i className="fa-solid fa-user"></i>
                                    <br></br>
                                    <NavLink to="/blog">Profiles</NavLink>
                                </li>

                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                    <ul className="d-flex align-items-center fgrtio list-unstyled m-0">
                        <li>
                            <NavLink
                                to="/signup"
                                className="btn fgrtp px-3 text-white d-none ms-4 d-lg-flex rounded-1"
                                style={{ color: "#fff" }}
                            >
                                Logout
                            </NavLink>
                        </li>
                    </ul>
                </Container>
            </Navbar>
            <div className="remodeling_colsp d-flex align-items-center justify-content-center">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg="6" xl="6">
                            <Card>
                                <Card.Body>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav
                                            variant="pills"
                                            className="flex-row gfrtyop"
                                        >
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" className="payment_cols">
                                                    <h5 className="m-0">Card Payments</h5>
                                                </Nav.Link>
                                            </Nav.Item>
                                            
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Form>
                                                    <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Card Holder Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter the Name" />
                                                    </Form.Group>
                                                    <Form.Group className="my-3" controlId="exampleForm.ControlInput2">
                                                        <Form.Label>Card Number</Form.Label>
                                                        <Form.Control type="number" placeholder="Enter the Number" />
                                                    </Form.Group>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Form.Label>Expiry Date</Form.Label>
                                                            <Form.Control type="text" placeholder="mm/yy" />
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formGridPassword">
                                                            <Form.Label>CVV Number</Form.Label>
                                                            <Form.Control className="w-50" type="number" placeholder="" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridPassword">

                                                        </Form.Group>
                                                    </Row>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="button_colss my-2 text-white btn px-4 rounded-0 font_family"
                                                        >
                                                            Payment
                                                        </button>
                                                    </div>
                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
}

export default Payments;
