import React from 'react'
import { Container, Table, Button } from 'react-bootstrap';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Complaints() {
    return (
        <>
            <Header />
            <Container className="">
                <div className='d-flex align-items-center justify-content-center h-100 py-3 dfrtyu_col'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name of Complaints</th>
                                <th>Date of Complaints</th>
                                <th>Complaints to</th>
                                <th>Complaint Details</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>ABC</td>
                                <td>02/07/2024</td>
                                <td>02/07/2024</td>
                                <td>XYZ</td>
                                <td>
                                    <Button variant="success" className='my-1'><i className="fa-regular fa-eye"></i></Button>{' '}
                                    <Button variant="danger" className='my-1'><i className="fa-solid fa-trash"></i></Button>{' '}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export default Complaints;