import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Footer from "./../Footer/Footer";
import Header from "../Header/Header";
import {
  API_URL,WEB_URL,IMG_URL,
  BLOG_LIST,
  BLOG_RELATED_LIST,
  BLOG_COMMENTS,
  ADD_BLOG_COMMENTS,
  MTAG_TITLE_BLOG_DETAILS,
  TITLE_BLOG_DETAILS,
} from "../../config/Config";
import { getLocalData } from "../../config/localStrorage";
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';

function BlogDetails() {
  const local = getLocalData();
  const { slug } = useParams();
  const [userData, setUserData] = useState([]);
  const [blogs, setblogData] = useState([]);
  const [blogCommentData, setblogCommentData] = useState([]);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  function blogList(id, label) {
    const APIURL = API_URL + BLOG_RELATED_LIST + "/" + encodeURIComponent(label) + "/" + id;
    fetch(APIURL)
      .then((res) => res.json())
      .then((response) => {
        const data = response.data;
        setblogData(data);
      });
  }
  function addblogComment() {
    var productUrl = API_URL + ADD_BLOG_COMMENTS;
    const formData = new FormData();
    if (comment === "") {
      toast.error("Please fill comment");
      return;
    }
    if (name === "") {
      toast.error("Please fill name");
      return;
    }
    if (email === "") {
      toast.error("Please fill email");
      return;
    }
    formData.append("comment", comment);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("blogSlugName", slug);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      if (response.data.status === true) {
        toast.success(response.data.msg);
        blogCommentList();
        setComment("");
        setName("");
        setEmail("");
        setWebsite("");
      }
    });
  }
  function blogCommentList() {
    var productUrl = API_URL + BLOG_COMMENTS;
    const formData = new FormData();
    formData.append("blogSlugName", slug);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setblogCommentData(response.data.data);
    });
  }
  /*function SMPost(sm, type, id) {
    var productUrl = API_URL + SM_SHARE;
    const formData = new FormData();
    formData.append("run_type", type);
    formData.append("param1", id);
    formData.append("sm", sm);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {

      toast.success(response.data.msg);

    });
  }*/
 
  useEffect(() => {
    setName(local.userName);
    setEmail(local.userEmail);

    const APIURL = API_URL + BLOG_LIST + "/" + slug;
    fetch(APIURL)
      .then((res) => res.json())
      .then((response) => {
         //console.log(response);

        if(response.status===true){
        const data = response.data;
        setUserData(data[0]);
        blogList(data[0].blogId, data[0].blogLabel);
        }
        else
        {
          window.location='/';
        }
      });

    blogCommentList();
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>{TITLE_BLOG_DETAILS}</title>
        <meta name="description" content={userData.metaDescription} />
        {/* <meta name="keywords" content={userData.metaKeywords.join(', ')} /> */}
        <meta property="og:title" content={userData.metaTitle} />
        <meta property="og:description" content={userData.metaDescription} />
        <meta property="og:url" content={`${WEB_URL+"blog/" + userData.blogSlugName}`} />
      </Helmet>
      <Container>
        <div className="">
          <div className="content mt-5">
            <div className="d-flex align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
              <a href="./FreeTime" className="sdeoi my-2">
                <div>
                  <span className="time">{userData.blogLabel}</span>
                </div>
              </a>
              <span className="ms-3 text-start my-2">
                {moment(userData.blogCreatedDate).format("MMM DD, YYYY")}
              </span>
              <div className="share_link ms-3 my-2">
                <div className="d-flex align-items-center">
                  {/* <span onClick={() => SMPost('x', 2, userData.blogId)}>
                    <i className="fa-brands fa-square-x-twitter handcursor"></i>
                  </span> */}

                  <a href={"http://twitter.com/share?url="+WEB_URL+"/blog/" + userData.blogSlugName+"&text="+userData.blogTitle+"&hashtags=bcrblogs"} target="_blank"><i className="fa-brands fa-square-x-twitter handcursor"></i></a>

                  {/*<a href="#" target="_blank" rel="noopener noreferrer">*/}
                  {/* <span onClick={() => SMPost('fb', 2, userData.blogId)}>
                    <i className="fab fa-facebook mx-3 handcursor"></i>
                  </span> */}
                  <a href={"http://www.facebook.com/sharer.php?u="+WEB_URL+"/blog/" + userData.blogSlugName} target="_blank"><i className="fab fa-facebook mx-3 handcursor"></i></a>

                  {/* <span onClick={() => { }}>
                    <i className="fab fa-instagram-square "></i>
                  </span> */}
                  {/* <span onClick={() => { }}>
                    <i className="fa-solid fa-envelope fa-envelopes mx-3"></i>
                  </span> */}

                  <a href={"mailto:?Subject=BCR Blogs-"+userData.blogTitle+"&Body=Dear,%0D%0A%0D%0APlease%20find%20Blogs%0D%0A "+WEB_URL+"/blog/" + userData.blogSlugName}><i className="fa-solid fa-envelope fa-envelopes mx-3"></i></a>
                </div>
              </div>
            </div>
            <h1>{userData.blogTitle}</h1>
            <div className="image_col d-flex flex-wrap">
              <img
                src={IMG_URL+'blogs/'+userData.blogImage}
                className="card-img-top img my-1"
                alt={""}
              />
              <div className="text-start ms-3 my-1" dangerouslySetInnerHTML={{ __html: userData.blogContent }} />
            </div>
            <Row className="">
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="likes_col mt-5">
                  <h2 className="m-0">
                    <span>You may also like...</span>
                  </h2>
                  <Row>
                    {blogs.length > 0 &&
                      blogs.map((res, ind) => (
                        <Col xl="4" lg="4" md="6" sm="4" className="my-2" key={ind}>
                          <div className="likes_colss" >
                            <div className="image_colss">
                              {res.blogImage !== "" && (
                                <img
                                src={IMG_URL+'blogs/'+res.blogImage}
                                  className="card-img-top imgeses"
                                  alt={res.blogTitle}
                                />
                              )}
                              <div className="d-flex align-items-center">
                                <a
                                  href={"/blog/" + res.blogSlugName}
                                  className="sdeoi"
                                >
                                  <div className="time">{res.blogLabel}</div>
                                </a>
                                <span className="ms-3 text-start">
                                  {" "}
                                  {moment(res.blogCreatedDate).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                              </div>
                              <p className="">
                                <h2 className="text-starts my-2">
                                  <a
                                    href={"/blog/" + res.blogSlugName}
                                    className="fw-bold"
                                  >{res.blogTitle}</a>
                                </h2>
                              </p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>

                <div className="thought_col mt-2">
                  <h3>
                    {blogCommentData.length} thoughts on “{userData.blogTitle}”
                  </h3>
                  {blogCommentData.length > 0 &&
                    blogCommentData.map((res) => {
                      return (
                        <div className="reply_col">
                          <p className="m-0 fw-bold">
                            {res.bgcName}
                            {" "}
                            {moment(res.bgcCreatedDate).format("MMM DD, YYYY")}
                          </p>

                          <p className="my-3 dfrtyui">{res.bgcComment}</p>
                        </div>
                      );
                    })}

                  <Row>
                    <Col lg="7" md="7">
                      <div className="form_colsss my-4">
                        <h3>Reply</h3>
                        <p>
                          <small>
                            Your email address will not be published. Required
                            fields are marked *
                          </small>
                        </p>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            Comment <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Comment"
                            onChange={(e) => setComment(e.target.value)}
                            rows={5}
                            value={comment}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            Email address<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Websites</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => setWebsite(e.target.value)}
                            value={website}
                          />
                        </Form.Group>
                        <Button
                          type="button"
                          onClick={addblogComment}
                          className="p-3 rounded-0 btn_grtyui"
                        >
                          Post Comment
                        </Button>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default BlogDetails;
