import React from 'react'
import { Container, Form, Row, Col, Button, Card, Tab, Tabs } from 'react-bootstrap';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
function Register() {
    return (
        <>
            <Header />
            <div className="login_col my-5">
                <Container>
                    <Tabs
                        defaultActiveKey="home"
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="home" title="Step 1">
                            <Row className='py-4'>
                                <Col xl={7} md={12} lg={8}>
                                    <div className="login_colss">
                                        <div className='header_colss p-3'>BCR values your privacy, and will only share this information with authorized parties.</div>
                                        <Form className='p-3 form_colss'>
                                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control type="text" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control type="text" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                                <Form.Label>Email address (optional)</Form.Label>
                                                <Form.Control type="email" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-2 w-50" controlId="formGroupPassword">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control type="number" placeholder="" />
                                            </Form.Group>
                                        </Form>
                                        <Form className='px-3 form_colss py-3'>
                                            {['checkbox',].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type={type}
                                                        id={`default-${type}`}
                                                        label={`I don't have an email address.`}
                                                    />


                                                </div>
                                            ))}
                                        </Form>
                                        <Form className='p-3 bg-white'>
                                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                                <Form.Label>Address line 1 (optional)</Form.Label>
                                                <Form.Control type="text" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-2 w-50" controlId="formGroupEmail">
                                                <Form.Label>Address line 2 (optional)</Form.Label>
                                                <Form.Control type="text" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                                <Form.Label>City (optional)</Form.Label>
                                                <Form.Control type="text" placeholder="" required />
                                            </Form.Group>
                                            <Form.Group className="mb-2 w-50" controlId="formGroupPassword">
                                                <Form.Label>State/province (optional)</Form.Label>
                                                <Form.Select aria-label="Default select example">
                                                    <option>Select/province(Optional)</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationCustom05" className="mb-2">
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control type="text" placeholder="Zip" required />
                                            </Form.Group>
                                            <Form.Group className="mb-2 w-75 border-1" controlId="formGroupPassword">
                                                <Form.Label>Country(Optional)</Form.Label>
                                                <Form.Select aria-label="Default select example">
                                                    <option>Select Country</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <div className="login_col text-end px-3">
                                                <Button variant="warning" className='button_cols my-3 fw-bold text-white'>Continue</Button>{' '}
                                            </div>
                                        </Form>

                                    </div>
                                </Col>
                                <Col xl={5} md={12} lg={4}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <span className='text_col fw-bold'>Business Headquarters Location</span>
                                            </Card.Text>
                                            <div className="d-flex align-items-baseline">
                                                <i className="fas fa-map-marker"></i>
                                                <span className='ms-2 fgfdhgt'>90 Creekwood Dr, <br></br>Lake Orion, MI 48362-1150</span>
                                            </div>
                                            <div className="d-flex align-items-baseline">
                                                <a href="fdg">
                                                    <i className="fas fa-phone-alt"></i>
                                                    <span className='ms-2 fgfdhgt'>(888) 544-7366</span>
                                                </a>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card className='my-3'>
                                        <Card.Body>
                                            <p>If you have any questions or concerns, please contact the BCR assigned to your complaint:</p>
                                            <Card.Text>
                                                <span className='text_col fw-bold'>BCR of Detroit & Eastern Michigan</span>
                                            </Card.Text>
                                            <div className="d-flex align-items-baseline">
                                                <i className="fas fa-map-marker"></i>
                                                <span className='ms-2 fgfdhgt'>
                                                    20300 W. Twelve Mile Rd<br></br>
                                                    Suite 202<br></br>
                                                    Southfield, MI 48076-6409
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-baseline">
                                                <a href="fdg">
                                                    <i className="fas fa-envelope"></i>
                                                    <span className='ms-2 fgfdhgt'>Email this BCR</span>
                                                </a>
                                            </div>
                                            <div className="d-flex align-items-baseline">
                                                <a href="fdg">
                                                    <i className="fas fa-phone-alt"></i>
                                                    <span className='ms-2 fgfdhgt'>(888) 544-7366</span>
                                                </a>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="profile" title="Step 2" className='mx-2'>
                            Tab content for Profile
                        </Tab>
                        <Tab eventKey="longer-tab" title="Step 3">
                            Tab content for Loooonger Tab
                        </Tab>
                    </Tabs>


                </Container>
            </div>
            <Footer />
        </>
    )
}

export default Register
