import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useParams,useNavigate } from "react-router-dom";
import { API_URL,IMG_URL, MY_PROFILE,UPDATE_PROFILE_DATA, CHANGE_COMPLAINT_STATUS, MTAG_TITLE_MY_PROFILE, MTAG_DESCRIPTION_MY_PROFILE, MTAG_KEYWORDS_MY_PROFILE, MTAG_URL_MY_PROFILE, TITLE_MY_PROFILE } from "../config/Config";
import { getLocalData } from "../config/localStrorage";
import axios from "axios";
import StarRatings from "react-star-ratings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';

const rowsPerPage = 3; // Number of rows to display per page
const rowsPerPager = 3; // Number of rows to display per page

function Profiles() {
    const { id } = useParams();
    const history = useNavigate();
    const linkStyle = {
        color: '#2770c4', // Text color
        textDecoration: 'none', // Remove underline by default
        border: '1px solid #2770c4', // Border style
        padding: '5px 10px', // Padding around the text
        borderRadius: '5px', // Rounded corners
      };
    const [companyData, setCompanyData] = useState([]);
    const [complaintsData, setComplaintsData] = useState([]);
    const [reviewsData, setReviewsData] = useState([]);
    const [colLg, setColLg] = useState("8");
    const [files, setFiles] = useState([]);
    const local = getLocalData();
    function searchFrm() {
        var productUrl = API_URL + MY_PROFILE;
        const formData = new FormData();
        formData.append("id", id);
        axios({
            method: "post",
            url: productUrl,
            data: formData,
        }).then(function (response) {
            setCompanyData(response.data.data[0]);
            setComplaintsData(response.data.complaints);
            setReviewsData(response.data.reviews);
        });
    }
    useEffect(() => {
        if (local.userType === '3') {
            setColLg("8");
        }
        searchFrm();
    }, []);

    const renderFileList = () => (
        <ul>
          {[...files].map((f, i) => (
            <li key={i}>
              <b>
                <div className="text-white css-rs23t5 ek0ml8b0">{f.type}</div>
                {f.name}
              </b>
            </li>
          ))}
        </ul>
      );
    const cancelComplaint = async (complaintId) => {
        const confirmed = window.confirm('Are you sure ? You want to cancel complaint request?');
        if (confirmed) {
            var local = getLocalData();
            var apiUrl = API_URL + CHANGE_COMPLAINT_STATUS;
            const config = {
                headers: {
                    Authorization: local.token,
                },
            };
            var userId = local.userId;
            const formData = new FormData();
            formData.append("complaintId", complaintId);
            formData.append("complaintStatus", "5");
            formData.append("userId", userId);
            axios
                .post(apiUrl, formData, config)
                .then((response) => {
                    searchFrm();
                    alert(response.data.message);
                    console.log(response.data);
                })
                .catch((error) => {
                    //redirectToErrorPage(error.message, router);
                });
        }
    };

    const updatePhoto = () => {
        if (files.length <= 0) {
          toast.error("Please select profile photo");
          return;
        }
        
        var productUrl = API_URL + UPDATE_PROFILE_DATA;
        const formData = new FormData();
        formData.append("userType", local.userType);
        formData.append("userId", local.userId);
        formData.append("id", id);
        if (files.length > 0) {
          [...files].forEach((file, i) => {
            formData.append(`file-${i}`, file, file.name);
          });
        }
    
        axios
          .post(productUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: local.token,
            },
          })
          .then((response) => {
            toast.success(response.data.msg);
            window.location.reload();
            //history('/profiles/'+id+'/'+local.userName);
          })
          .catch((error) => {
            toast.success(error.message);
            //redirectToErrorPage(error.message, router);
          });
      };

    const [page, setPage] = useState(0);
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };
    const pageCount = Math.ceil(complaintsData.length / rowsPerPage);

    const [pager, setPager] = useState(0);
    const handleChangePager = (newPager) => {
        setPager(newPager);
    };
    const pageRCount = Math.ceil(reviewsData.length / rowsPerPager);

    return (
        <>
            <Header />
            <Helmet>
                <title>{TITLE_MY_PROFILE}</title>
                <meta name="description" content={MTAG_DESCRIPTION_MY_PROFILE} />
                {/* <meta name="keywords" content={MTAG_KEYWORDS_MY_PROFILE.join(', ')} /> */}
                <meta property="og:title" content={MTAG_TITLE_MY_PROFILE} />
                <meta property="og:description" content={MTAG_DESCRIPTION_MY_PROFILE} />
                <meta property="og:url" content={MTAG_URL_MY_PROFILE+`profiles/${local.userId}/${local.userSlugTitle}`} />
            </Helmet>
            <div className="height_colss">
                <div className="remodeling_cols">
                    <Container>
                        <div className="accredited_col">
                            <div className="d-flex align-items-center">
                                {companyData.imagePath !== "" && (
                                    <div className="bcr_accrediated">
                                        <div className=" bg-white">
                                            <img src={IMG_URL+'users/'+companyData.imagePath} height={70} width={70} alt='' />
                                        </div>
                                    </div>
                                )}
                                
                                <div className="contect_col ms-3">
                                    <h6 className="m-0 business_cols">
                                        {local.userType === "3"
                                            ? "My Profile"
                                            : "My Profile"}

                                    </h6>
                                    <h3 className="m-0">{companyData.name}</h3>
                                    <p>
                                        <small className="fw-bold">{companyData.bcName}</small>
                                    </p>
                                </div>
                            </div>
                            <div className="align-items-center">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => setFiles(e.target.files)}
                                />
                                {renderFileList()}
                                <button
                                    type="button"
                                    onClick={() => {
                                        updatePhoto();
                                    }}
                                    className="btn btn-success"
                                    >
                                    {" "}
                                    Update Photo
                                </button>
                            </div>
                        </div>
                    </Container>
                   
                </div>
                <Container>
                    <Row>
                        <Col lg="4" md="6">
                            <Card className="my-3 dfrty">
                                <Card.Body>
                                    <Card.Text>
                                        <h5 className="fw-normal my-2">Contact Information 
                                           <NavLink to={"/editprofile/" + id + "/contactdetails"} style={linkStyle} className="fgrtps rounded-1 float-end">
                                            <small>Edit</small>
                                           </NavLink>
                                        </h5>
                                    </Card.Text>
                                    <div className="d-flex align-items-baseline mt-2">
                                        <i className="fas fa-map-marker"></i>
                                        <span className="ms-2 fgfdhgt">
                                            {companyData.address}
                                            <br></br>
                                            {companyData.cityName}, {companyData.regionName},
                                            {companyData.pinCode}
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-baseline my-2">
                                        <a href={companyData.website} target="_blank">
                                            <i className="fab fa-firefox-browser"></i>
                                            <span className="ms-2 fgfdhgt">Visit Website</span>
                                        </a>
                                    </div>

                                    {/*<div className="d-flex align-items-baseline my-2">
                                        <a href={"mailto:" + companyData.email}>
                                            <i className="fas fa-envelope"></i>
                                            <span className="ms-2 fgfdhgt font_family">Email this BCR</span>
                                        </a>
                                    </div>*/}

                                    <div className="d-flex align-items-baseline">
                                        <i className="fas fa-phone-alt"></i>
                                        <div>
                                            <a href={"tel:" + companyData.phoneNumber}>
                                                <span className="ms-2 fgfdhgt">
                                                    {companyData.phoneNumber}
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={colLg} md="6">
                            <Card className="my-3">
                                <Card.Body>
                                    {/* <Card.Text>
                                        <h5 className="fw-normal my-2">Reviews</h5>
                                            <a
                                                href={local.userName === null ? "#" : "/review/" + id}
                                                onClick={() =>
                                                    local.userName === null
                                                        ? toast.error("Please login first")
                                                        : ""
                                                }
                                            >
                                                <span className="registercomplaint">
                                                    Be the First to Review!
                                                </span>
                                            </a>
                                    </Card.Text> */}
                                    <h5 className="fw-normal my-2">My Reviews</h5>
                                    <div>
                                        <div className="table_collsss">
                                            {reviewsData.length>0 ?
                                            <>
                                                <table className="dfrtyuyo">
                                                    <thead>
                                                        <tr>
                                                            <th className="table_col fw-bold">Business</th>
                                                            <th className="table_col fw-bold">Comments</th>
                                                            <th style={{ width: "20%" }} className="table_col fw-bold">Ratings</th>
                                                            {/* <th className="table_col fw-bold">Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reviewsData.slice(pager * rowsPerPager, pager * rowsPerPager + rowsPerPager).map((row) => (
                                                            <tr>
                                                                <td>
                                                                <small>{local.userType === "2"
                                                                        ? row.custdCustomerName
                                                                        : row.cdCompanyName}</small>
                                                                </td>
                                                                <td><small>{row.crComment}</small></td>
                                                                <td>
                                                                {row.crRating >0
                                                                    ? <StarRatings
                                                                    rating={parseFloat(row.crRating)}
                                                                    starRatedColor="#FFD700"
                                                                    numberOfStars={5}
                                                                    name="rating"
                                                                    starDimension="20px"
                                                                    starSpacing="2px"
                                                                    />
                                                                    : <StarRatings
                                                                    rating={0}
                                                                    starRatedColor="#FFD700"
                                                                    numberOfStars={5}
                                                                    name="rating"
                                                                    starDimension="20px"
                                                                    starSpacing="2px"
                                                                />}
                                                                </td>
                                                                {/* <td>
                                                                    {row.cStatus === "3"
                                                                        ? <div className="d-flex align-items-center"><a href="/makepayment" className="fgrtps d-none d-lg-flex rounded-1">
                                                                            Request Resolution
                                                                        </a></div>
                                                                        : ""}
                                                                    <div className="d-flex align-items-center"><a href={"/complaintsdetails/" + row.cSlugName} className="fgrtps d-none d-lg-flex rounded-1">
                                                                        View
                                                                    </a></div>
                                                                    {row.cStatus === "3"
                                                                        ? <div className="d-flex align-items-center"><a href="#" onClick={(id) => cancelComplaint(row.cId)} className="fgrtps d-none d-lg-flex rounded-1">
                                                                        Cancel
                                                                    </a></div>
                                                                        : ""}

                                                                </td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="d-flex align-items-center justify-content-between my-2">
                                                    <button className="previous_col" onClick={() => handleChangePager(pager - 1)} disabled={pager === 0}>
                                                        Previous
                                                    </button>
                                                    <span className="pages_col">Page {pager + 1} of {pageRCount}</span>
                                                    <button className="next_col" onClick={() => handleChangePager(pager + 1)} disabled={pager === pageRCount - 1}>
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                            :
                                                <><div className="d-flex align-items-center justify-content-between my-2">
                                                   <span> No Reviews Found</span>                                         
                                                </div></>}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card className="mb-3 ">
                                <Card.Body>
                                    <Card.Text>
                                        <h5 className="fw-normal my-2">Complaints</h5>
                                        <p>
                                            {/* <small>This business has 0 complaints</small> */}
                                        </p>
                                    </Card.Text>
                                    <div className="table_collsss">

                                    {complaintsData.length>0 ?
                                    <>
                                        <table className="dfrtyuio">
                                            <thead>
                                                <tr>
                                                    <th className="table_col fw-bold" style={{ width: "25%" }}>Name</th>
                                                    <th className="table_col fw-bold" style={{ width: "25%" }}>Complaint</th>
                                                    <th className="table_col fw-bold" style={{ width: "25%" }}>Payment Status</th>
                                                    <th className="table_col fw-bold" style={{ width: "25%" }}>Status</th>
                                                    <th style={{ width: "37%" }} className="table_col fw-bold">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {complaintsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                    <tr>
                                                        <td>
                                                        <small> {local.userType === "2"
                                                                ? row.custdCustomerName
                                                                : row.cdCompanyName} </small>
                                                        </td>
                                                        <td><small>{row.cTitle}</small></td>
                                                        <td><small>{row.paymentStatus}</small></td>
                                                        <td><small>{row.status}</small></td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <a href={"/complaint/" +row.slugTitle+"/"+ row.cSlugName} style={linkStyle} className="fgrtps  d-lg-flex rounded-1">
                                                                <small>View</small>
                                                            </a>
                                                            {row.cStatus === "3"
                                                                ? <a href={"/makepayment/" + row.cId} style={linkStyle} className="fgrtps d-lg-flex rounded-1 mx-2">
                                                                    <small>Request Resolution</small>
                                                                </a>
                                                                : ""}
                                                            
                                                            {row.cStatus === "3"
                                                                ? <a href="#" onClick={(id) => cancelComplaint(row.cId)} style={linkStyle} className="fgrtps  d-lg-flex rounded-1">
                                                                <small>Cancel</small>
                                                            </a>
                                                                : ""}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="d-flex align-items-center justify-content-between my-2">
                                            <button className="previous_col" onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
                                                Previous
                                            </button>
                                            <span className="pages_col">Page {page + 1} of {pageCount}</span>
                                            <button className="next_col" onClick={() => handleChangePage(page + 1)} disabled={page === pageCount - 1}>
                                                Next
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="d-flex align-items-center justify-content-between my-2">
                                            <span> No Complaints Found</span>                                         
                                        </div>
                                    </>}

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        

                    </Row>
                </Container>
                {local.userType === "2"
                    ? <Container className="mb-5">
                        <Row>
                            <Col lg="4" md="6" className="my-2">
                                <Card className="my-3 h-100">
                                    <Card.Body>
                                        <Card.Text>
                                            <h5 className="fw-normal my-2 services_colss">
                                                Products & Services
                                                <NavLink to={"/editproductservices/" + id } style={linkStyle} className="fgrtps rounded-1 float-end">
                                                  <small>Edit</small>
                                                </NavLink>
                                            </h5>
                                        </Card.Text>
                                        {companyData.productServices ? 
                                            <div dangerouslySetInnerHTML={{__html: companyData.productServices}}></div>
                                        :
                                        <>
                                        <p className="services_colss fw-bold m-0">
                                            After Hours Remodeling, LLC provides the following services
                                        </p>
                                        <ListGroup>
                                            <ListGroup.Item>Roofing Contractors</ListGroup.Item>
                                            <ListGroup.Item>Bathroom</ListGroup.Item>
                                            <ListGroup.Item>Flat Roofing Contractors</ListGroup.Item>
                                            <ListGroup.Item>Floor Installation</ListGroup.Item>
                                            <ListGroup.Item>Flooring Contractors</ListGroup.Item>

                                            <ListGroup.Item>Hardwood Floor Contractors</ListGroup.Item>
                                            <ListGroup.Item>Hardwood Floors</ListGroup.Item>
                                            <ListGroup.Item>Home Improvement</ListGroup.Item>
                                            <ListGroup.Item>Home Repair</ListGroup.Item>
                                            <ListGroup.Item>Home Services</ListGroup.Item>

                                            <ListGroup.Item>Kitchen Cabinets</ListGroup.Item>
                                            <ListGroup.Item>Raised Floor</ListGroup.Item>
                                            <ListGroup.Item>Roofing Consultants</ListGroup.Item>
                                        </ListGroup>
                                        </>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="4" md="6" className="my-2">
                                <Card className="my-3 h-100">
                                    <Card.Body>
                                        <Card.Text>
                                            <h5 className="fw-normal my-2 services_colss">
                                                Business Details
                                                <NavLink to={"/editbusinessdetails/" + id } style={linkStyle} className="fgrtps rounded-1 float-end">
                                                  <small>Edit</small>
                                                </NavLink>
                                            </h5>
                                        </Card.Text>
                                        {companyData.businessDetails ? 
                                            <div dangerouslySetInnerHTML={{__html: companyData.businessDetails}}></div>
                                        :
                                        <>
                                        <p className="fw-bold m-0">Location of This Business</p>
                                        <p className="m-0">
                                            21 Providence Point Ln, Hartwell, GA 30643-8066
                                        </p>
                                        {/*
                                        <div className="d-flex align-items-baseline">
                                            <a href="fdg">
                                                <i className="fas fa-envelope"></i>
                                                <span className="ms-2 fgfdhgt">Email this Business</span>
                                            </a>
                                        </div>
                                        */}
                                        <p>
                                            <span className="fw-bold">BCR File Opened</span> :4/11/2019
                                        </p>
                                        <p>
                                            <span className="fw-bold">Years in Business</span> :6
                                        </p>
                                        <p>
                                            <span className="fw-bold">Business Started</span> :3/11/2018
                                        </p>
                                        <p>
                                            <span className="fw-bold">Type of Entity</span> :Limited
                                            Liability Company(LLC)
                                        </p>
                                        <p className="fw-bold m-0">Business Management</p>
                                        <p>Mr. Adam Jones, Owner</p>

                                        <div className="contact_infoermation">
                                            <h6 className="fw-bold">Contact Information</h6>
                                            <p className="m-0">Principal</p>
                                            <p className="m-0">Mr. Adam Jones, Owner</p>
                                            <p className="m-0">Customer Contact</p>
                                            <p className="">Mr. Adam Jones, Owner</p>
                                        </div>
                                        {/*
                                        <div className="contact_infoermation">
                                            <h6 className="fw-bold m-0">
                                                Additional Contact Information
                                            </h6>
                                            <p className="m-0">Email Addresses</p>
                                            <div className="d-flex align-items-baseline">
                                                <a href="fdg">
                                                    <i className="fas fa-envelope"></i>
                                                    <span className="ms-2 fgfdhgt">
                                                        Email this Business
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        */}
                                        </>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            {local.userType === "2"
                            ? <Col lg="4" md="6">
                                <Card className="my-3 dfrty">
                                <Card.Body>
                                    <Card.Text as="div">
                                        <h5 className="fw-normal my-2">
                                        BCR Rating
                                        </h5>
                                    </Card.Text>
                                    <div className="image_cols">
                                        <span className="text-blue-brand me-3 font-bold">{companyData.bcrRating}</span>
                                    </div>
                                    <div className="accrediated_col my-2">
                                        <a href="#" className="fdgftyt_col">
                                        <p>{companyData.bcrRatingRemarks}</p> 
                                        </a>
                                    </div>
                                    <p className="customer_review">
                                        Customer Reviews are not used in the <br></br> calculation
                                        of BCR Rating
                                    </p>
                                    
                                    </Card.Body>
                                </Card>
                            </Col>
                            : ""}
                        </Row>
                    </Container>
                    : ""}
            </div>
            <ToastContainer />
            <Footer />
        </>
    );
}

export default Profiles;
