import "./App.css";
import Home from "./MyComponents/Header/Home";
import Footer from "./MyComponents/Footer/Footer";
import AddCompalaint from "./MyComponents/Complaint/AddComplaints";
import Complaints from "./MyComponents/Complaint/Complaints";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./MyComponents/Header/Header";
import FreeTime from "./MyComponents/Blog/FreeTime";
import Blog from "./MyComponents/Blog/Blog";
import ForgotPassword from "./MyComponents/ForgotPassword";
import ChangePassword from "./MyComponents/ChangePassword";
import Login from "./MyComponents/Login";
import SignUp from "./MyComponents/SignUp";
import BenefitCompany from "./MyComponents/BenefitCompany";
import BenefitCustomer from "./MyComponents/BenefitCustomer";
import ResolutionProcess from "./MyComponents/ResolutionProcess";
import SignUpCustomer from "./MyComponents/SignUpCustomer";
import Review from "./MyComponents/Review";
import Register from "./MyComponents/Register";
import BlogDetails from "./MyComponents/Blog/BlogDetails";
//import Paginations from "./MyComponents/Blog/Paginations";
import AboutUs from "./MyComponents/About_us";
import ContactUs from "./MyComponents/Contact_us";
import Details from "./MyComponents/Details";
import SearchCompany from "./MyComponents/Header/SearchCompany";
import Search from "./MyComponents/Search";
import RegisterComplaint from "./MyComponents/RegisterComplaint";
import SquarePayment from "./MyComponents/SquarePayment";
import ComplaintsDetails from "./MyComponents/Complaint/ComplaintsDetails";
import Profiles from "./MyComponents/Profile";
import EditProfile from "./MyComponents/EditProfile";
import EditProductServices from "./MyComponents/EditProductServices";
import EditBusinessDetails from "./MyComponents/EditBusinessDetails";
import Payments from "./MyComponents/Payment";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/header" element={<Header />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/AddComplaints" element={<AddCompalaint />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/freetime" element={<FreeTime />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/benefitcompany" element={<BenefitCompany />} />
          <Route path="/benefitcustomer" element={<BenefitCustomer />} />
          <Route path="/resolutionprocess" element={<ResolutionProcess />} />
          <Route path="/signupcustomer" element={<SignUpCustomer />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/complaints" element={<Complaints />} />
          {/*<Route path="/complaintsdetails" element={<ComplaintsDetails />} />*/}
          <Route path="/complaint/:userSlugTitle/:slug" element={<ComplaintsDetails />} />
          <Route path="/review" element={<Review />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          {/*<Route path="/paginations" element={<Paginations />} />*/}
          <Route path="/profiles/:id/:userSlugTitle" element={<Profiles />} />
          <Route path="/editprofile/:id/:userSlugTitle" element={<EditProfile />} />
          <Route path="/editproductservices/:id" element={<EditProductServices />} />
          <Route path="/editbusinessdetails/:id" element={<EditBusinessDetails />} />
          <Route path="/payment" element={<Payments />} />
          <Route path="/search/:business/:category/:city" element={<Search />} />
          <Route path="/searchCompany" element={<SearchCompany />} />
          <Route path="/details/:id/:userSlugTitle" element={<Details />} />
          <Route path="/registercomplaint/:id" element={<RegisterComplaint />}/>
          <Route path="/review/:id" element={<Review />} />
          <Route path="/makepayment/:id" element={<SquarePayment />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
