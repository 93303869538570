import React from 'react'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { MTAG_DESCRIPTION_ABOUT_US, MTAG_KEYWORDS_ABOUT_US, MTAG_TITLE_ABOUT_US, MTAG_URL_ABOUT_US, TITLE_ABOUT_US } from '../config/Config';
function AboutUs() {
    return (
        <>
            <Header />
            <Helmet>
                <title>{TITLE_ABOUT_US}</title>
                <meta name="description" content={MTAG_DESCRIPTION_ABOUT_US} />
                {/* <meta name="keywords" content={MTAG_KEYWORDS_ABOUT_US.join(', ')} /> */}
                <meta property="og:title" content={MTAG_TITLE_ABOUT_US} />
                <meta property="og:description" content={MTAG_DESCRIPTION_ABOUT_US} />
                <meta property="og:url" content={MTAG_URL_ABOUT_US} />
            </Helmet>
            <Container className="about_col d-xl-block d-lg-block d-md-block d-sm-none d-none">
                <Row className='align-items-center justify-content-center h-100'>
                    <Col lg="10">
                        <Card className="my-3">
                            <Card.Body className='p-0'>
                                <Row>
                                    <Col lg="6" sm="12" className='my-2'>
                                        <div className="contactss_colss p-3">
                                            <h2 className='touch fw-bold'>About Us</h2>
                                            <p className='font_family'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore accusamus recusandae illum autem pariatur placeat nobis perspiciatis laboriosam ducimus, numquam aut inventore! Debitis hic autem iure soluta suscipit non dicta esse cupiditate tempora molestias aspernatur, neque cumque ratione distinctio ullam quas. Ipsam eum quod, quia architecto voluptatem, delectus, quibusdam consectetur eaque nulla voluptate dicta nesciunt fugit nostrum quas sint iusto illo sequi consequatur quasi atque accusamus.</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" sm="12" className='my-2'>
                                        <div className="images_colss text-center p-3">
                                            <img src={require("./../img/About.png")} className="card-img-top imgss_colsio" alt={"working"} />
                                        </div>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container className="d-xl-none d-lg-none d-md-none d-sm-block d-block">
                <Row className='align-items-center justify-content-center h-100'>
                    <Col lg="10">
                        <Card className="my-3">
                            <Card.Body className='p-0'>
                                <Row>
                                    <Col lg="6" sm="12" className='my-2'>
                                        <div className="contactss_colss p-3">
                                            <h2 className='touch fw-bold'>About Us</h2>
                                            <p className='font_family'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore accusamus recusandae illum autem pariatur placeat nobis perspiciatis laboriosam ducimus, numquam aut inventore! Debitis hic autem iure soluta suscipit non dicta esse cupiditate tempora molestias aspernatur, neque cumque ratione distinctio ullam quas. Ipsam eum quod, quia architecto voluptatem, delectus, quibusdam consectetur eaque nulla voluptate dicta nesciunt fugit nostrum quas sint iusto illo sequi consequatur quasi atque accusamus.</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" sm="12" className='my-2'>
                                        <div className="images_colss text-center p-3">
                                            <img src={require("./../img/About.png")} className="card-img-top imgss_colsio" alt={"working"} />
                                        </div>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default AboutUs;