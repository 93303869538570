import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import styles from "./styles/Home.module.css";
import { Container,  Row, Col,  Card } from 'react-bootstrap';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import {
  API_URL, SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID,COMPLAINT_DETAILS,
  SAVE_PAYMENT_DETAILS,
  MTAG_TITLE_SQUARE_PAYMENT,
  TITLE_SQUARE_PAYMENT,
} from "../config/Config";
import { getLocalData } from "../config/localStrorage";
import {  toast, ToastContainer  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate,useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';


export default function Home() {
  const history = useNavigate();
  const { id } = useParams();
  const [paymentMsg, setPaymentMsg] = useState("");
  const [complaintData, setComplaintData] = useState([]);
  const local = getLocalData();
  var payAmount = 100;
  if(local.userType==='2'){
    payAmount = 200;
  }

  const getComplaintDetails = () => {
    var productUrl = API_URL + COMPLAINT_DETAILS;
    const formData = new FormData();
    formData.append("userId", local.userId);
    formData.append("cId", id);
    formData.append("slug", "");
    formData.append("orderType", '1');
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      setComplaintData(response.data.data[0]);
    });
  }

  useEffect(() => {
    getComplaintDetails();
  }, []);
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_SQUARE_PAYMENT}</title>
      </Helmet>
      <Container className='frtyuop'>
        <p></p>
        <Row className='align-items-center justify-content-center h-100'>
            <Col lg="6">
            <div className="ms-3"><b>Company Name:</b> {complaintData.cdCompanyName}</div>
            <div className="ms-3"><b>Complaint: </b> {complaintData.cDescription}</div>
          </Col>
          <Col lg="6">
            <div className="ms-3">
              <b>Complaint Type: </b><span className="span_col">{complaintData.cTitle}</span>
            </div>
            <div className="ms-3">
              <b>Status: </b><span className="span_col">{complaintData.status}</span>
            </div>
          </Col>
        </Row>
        <Row className='align-items-center justify-content-center h-100'>
            <Col xl="6" lg="6" sm="12">
              
            <div className="ms-3"><p></p><b>Please make payment to resolve this complaint.</b></div>
          </Col>
          
        </Row>
        <Row className='align-items-center justify-content-center h-100'>
          <Col xl="6" lg="6" sm="12">
            <Card className='my-3'>
              <Card.Header>Credit Card Details</Card.Header>
              <Card.Body>
                <div className={styles.container}>
                  <h5 className="my-2">Amount: ${payAmount}</h5>
                  <PaymentForm
                    applicationId={SQUARE_APPLICATION_ID}
                    /*cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                      console.log('token:', token);
                      console.log('verifiedBuyer:', verifiedBuyer);
                    }}*/
                    cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                      try {
                        var complaintId = id;
                        const userId = local.userId;
                        const APIURL = API_URL + SAVE_PAYMENT_DETAILS;
                        const response = await fetch(APIURL, {
                          method: "POST",
                          headers: {
                            "Content-type": "application/json",
                          },
                          body: JSON.stringify({
                            complaintId: complaintId,
                            userId: userId,
                            sourceId: token.token,
                            paidAmount: payAmount,
                            currencyCode: 'USD',
                          }),
                        });
                        //console.log(await response.json());
                        const mdata = await response.json();
                        setPaymentMsg(mdata.msg);
                        toast.success(mdata.msg);
                        setTimeout(() => {
                          history("/profiles/" + userId+"/"+local.userSlugTitle);
                        }, 3000);
                        //alert(mdata.msg);
                      } catch (error) {
                        console.error('Error processing payment:', error);
                        // Handle errors as necessary
                      }
                    }}
                    locationId={SQUARE_LOCATION_ID}
                    //locationId='XXXXXXXXXX'

                    createPaymentRequest={() => ({
                      countryCode: "US",
                      currencyCode: "USD",
                      total: {
                        amount: "100.00",
                        label: "Total",
                      },
                    })}
                  >
                    <CreditCard
                    />
                  </PaymentForm>
                  <span id="paymentMsg">{paymentMsg}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
      <ToastContainer />
      <Footer />
    </>
  )
}