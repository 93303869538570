import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form } from "react-bootstrap";
import { API_URL,IMG_URL,WEB_URL, COMPLAINT_DETAILS, ADD_COMPLAINT_REPLY,CHECK_PROFANITY, MTAG_TITLE_COMPLAINT_DETAILS, TITLE_COMPLAINT_DETAILS } from "../../config/Config";
import { getLocalData } from "../../config/localStrorage";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';

function ComplaintsDetails() {
  const { userSlugTitle } = useParams();
  const { slug } = useParams();
  const local = getLocalData();
  const history = useNavigate();
  const [complaintData, setComplaintData] = useState([]);
  const [complaintConversationData, setComplaintConversationData] = useState([]);
  const [comment, setComment] = useState("");
  const [cId, setCId] = useState("");
  const [custdId, setCustdId] = useState("");
  const [cdId, setCdId] = useState("");
  const [cComplaintUserId, setCComplaintUserIdd] = useState("");
  const [isProfanity, setIsProfanity] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const getComplaintDetails = () => {
    var productUrl = API_URL + COMPLAINT_DETAILS;
    const formData = new FormData();
    formData.append("userId", local.userId);
    formData.append("cId", "0");
    formData.append("slug", slug);
    formData.append("orderType", '1');
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      //console.log(response)
      if(response.data.status===true){
      setComplaintData(response.data.data[0]);
      setCId(response.data.data[0].cId);
      setCustdId(response.data.data[0].custdId);
      setCdId(response.data.data[0].cdId);
      setCComplaintUserIdd(response.data.data[0].cComplaintUserId);
      setComplaintConversationData(response.data.conversations);
      }
      else{
        window.location='/';
      }

    });
  }
  useEffect(() => {
    getComplaintDetails();
  }, []);

  function addComplaintReply() {
    var productUrl = API_URL + ADD_COMPLAINT_REPLY;
    const formData = new FormData();
    if (comment === "") {
        toast.error("Please Enter Description");
      return;
    }else if (isProfanity === false) {
      toast.error("Profanity is not allowed on this platform");
    return;
  }
    
    formData.append("cId", cId);
    formData.append("custdId", custdId);
    formData.append("cdId", cdId);
    formData.append("replyUserId", local.userId);
    formData.append("comment", comment);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      if (response.data.status === true) {
        toast.success(response.data.msg);
        getComplaintDetails();
        setComment('');
        //alert(response.data.msg);
        history('/complaint/'+userSlugTitle+'/'+slug);
      }
    });
  }

  const checkProfanity = (e) => {
    setErrorMsg('');
    var productUrl = API_URL + CHECK_PROFANITY;
      const formData = new FormData();
      formData.append("text", comment);
      axios({
          method: "post",
          url: productUrl,
          data: formData,
      }).then(function (response) {
          var status = response.data.status;
          var error_msg = response.data.msg;
          setIsProfanity(status);
          if(status===false){
            setErrorMsg(error_msg);
          }
      });
  };

  /*function SMPost(sm,type,id) {
    var productUrl = API_URL + SM_SHARE;
    const formData = new FormData();
    formData.append("run_type", type);
    formData.append("param1", id);
    formData.append("sm", sm);
    axios({
      method: "post",
      url: productUrl,
      data: formData,
    }).then(function (response) {
      
        toast.success(response.data.msg);
      
    });
  }*/
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_COMPLAINT_DETAILS}</title>
          <meta name="description" content={complaintData.metaDescription} />
          {/* <meta name="keywords" content={complaintData.metaKeywords.join(', ')} /> */}
          <meta property="og:title" content={complaintData.metaTitle} />
          <meta property="og:description" content={complaintData.metaDescription} />
          <meta property="og:url" content={`${WEB_URL+"complaintsdetails/"+complaintData.slugTitle+ "/" + complaintData.cSlugName}`} />
      </Helmet>
      <Container className="">
        <div className="d-flex">
          <div className="content mt-5">
            <div className="d-flex flex-wrap">
              <span className="sdeoi">
                <div className="time">
                  <img src={IMG_URL+'users/'+complaintData.cdImage} height={70} width={70} alt=''/>
                </div>
              </span>
              <div>
                <div className="d-flex align-items-center mb-2">
                  <span className="ms-3">{moment(complaintData.cCreatedDate).format(
                    "MMM DD, YYYY"
                  )}</span>
                  <div className="share_link ms-3">
                    <div className="d-flex align-items-center">
                    {/* <span onClick={() => SMPost('x',1,complaintData.cId)}>
                        <i className="fa-brands fa-square-x-twitter handcursor"></i>
                    </span> */}
                    <a href={"http://twitter.com/share?url="+WEB_URL+"/complaintsdetails/" + userSlugTitle+"/"+ complaintData.cSlugName+"&text="+complaintData.cTitle+"&hashtags=bcrcomplaints"} target="_blank"><i className="fa-brands fa-square-x-twitter handcursor"></i></a>

                      {/* <span onClick={() => SMPost('fb',1,complaintData.cId)}>
                        <i className="fab fa-facebook mx-3 handcursor"></i>
                      </span> */}

                      <a href={"http://www.facebook.com/sharer.php?u="+WEB_URL+"/complaintsdetails/" + userSlugTitle+"/"+ complaintData.cSlugName} target="_blank"><i className="fab fa-facebook mx-3 handcursor"></i></a>


                      {/* <span onClick={() => {}}>
                        <i className="fab fa-instagram-square "></i>
                        </span> */}
                      {/* <span onClick={() => {}}>
                        <i className="fa-solid fa-envelope fa-envelopes mx-3"></i>
                      </span> */}
                      <a href={"mailto:?Subject=BCR Complaint-"+complaintData.cTitle+"&Body=Dear,%0D%0A%0D%0APlease%20find%20Complaint%0D%0A "+WEB_URL+"/complaintsdetails/" + userSlugTitle+"/"+ complaintData.cSlugName}><i className="fa-solid fa-envelope fa-envelopes mx-3"></i></a>

                    </div>
                  </div>

                </div>
                <Row>
                  <Col lg="6">
                
                  <div className="ms-3"><b>Company Name:</b> {complaintData.cdCompanyName}</div>
                  <div className="ms-3"><b>Complaint: </b> {complaintData.cDescription}</div>
                
                </Col>
                <Col lg="6">
                
                  <div className="ms-3">
                    <b>Complaint Type: </b><span className="span_col">{complaintData.cTitle}</span>
                  </div>
                  <div className="ms-3">
                    <b>Status: </b><span className="span_col">{complaintData.status}</span>
                  </div>
                
                </Col>
                </Row>
              </div>
            </div>

          </div>
        </div>
        <Row className="align-items-center justify-content-center">
          <Col lg="10">
            <div className="complaint_cols my-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="icons_cols">
                    <i className="fa-solid fa-user"></i>
                  </div>
                  <div className="ms-2">
                    <h6 className="m-0">Complaint Conversation</h6>
                    <p className="m-0">{moment(complaintData.cCreatedDate).format(
                    "MMM DD, YYYY"
                  )}</p>
                  </div>
                </div>
                
              </div>
              <p className="d-flex align-baseline ms-5 my-3">{complaintData.cDescription}</p>

              {complaintConversationData.map((row, index) => (
                    // <div className="d-flex align-baseline ms-5 sdert_cols">
                    <div className="d-flex align-baseline ms-5 my-3">
                        <div className="icons_cols">
                        <i className="fa-solid fa-user"></i>
                        </div>
                        <div className="ms-2">
                        <h6 className="m-0"> 
                        {row.userTypeName}
                        : {row.replyUserName}</h6>
                        <p className="m-0">{moment(row.comCreatedDate).format(
                    "MMM DD, YYYY"
                  )}</p>
                        <p>{row.comDescription}</p>
                        </div>
                    </div>
                ))}

              {local.userId === cComplaintUserId || local.customerId===custdId || local.companyId===cdId
                 ?  <div className="thought_col mt-2 ms-5">
                        <div className="thought_col mt-2">
                        <Row>
                            <Col lg="7" md="7">
                            <div className="form_colsss my-4">
                                <h5>Reply<span style={{ color: "red" }}>*</span></h5>
                                <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                                >
                                <Form.Control
                                    as="textarea"
                                    placeholder="Comment"
                                    onChange={(e) => setComment(e.target.value)}
                                    onKeyUp={(e) => checkProfanity(e)}
                                    rows={5}
                                    value={comment}
                                />
                                <span style={{ fontWeight: "bold", color: "red", }} >
                                  {errorMsg}
                                </span>
                                </Form.Group>
                                <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                                >
        
                                </Form.Group>
                                <div className="text-end">
                                <button
                                    type="button"
                                    onClick={() => addComplaintReply()}
                                    className="button_colss my-3 fw-bold text-white btn rounded-0 me-2"
                                  >
                                    Save
                                </button>

                                </div>
                            </div>
                            </Col>
                        </Row>
                        </div>
                    </div>
                : ""}

            </div>
          </Col>
        </Row>

        <ToastContainer />
      </Container>
      <Footer />
    </>
  )
}

export default ComplaintsDetails;