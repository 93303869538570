import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import { getLocalData } from "../config/localStrorage";
import {
  API_URL,
  CITY_STATE_GET,
  MTAG_DESCRIPTION_CONTACT_DETAILS,
  MTAG_KEYWORDS_CONTACT_DETAILS,
  MTAG_TITLE_CONTACT_DETAILS,
  MTAG_URL_CONTACT_DETAILS,
  MY_PROFILE,
  TITLE_CONTACT_DETAILS,
  UPDATE_CONTACT_DETAILS_PROFILE,
} from "../config/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { Helmet } from 'react-helmet';

function EditProfile() {
  const { id, userSlugTitle } = useParams();
  const history = useNavigate();
  const local = getLocalData();
  const [loading, setLoading] = useState(true);
  const [zipError, setzipError] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [cityState, setCityState] = useState({label:'Select City, State', value:''});

  function getContactDetails() {
    var productUrl = API_URL + MY_PROFILE;
    const formData = new FormData();
    formData.append("id", id);
    axios({
        method: "post",
        url: productUrl,
        data: formData,
    }).then(function (response) {
        setCompanyData({
          address: response.data.data[0].address,
          zip: response.data.data[0].pinCode,	
          phoneNumber: response.data.data[0].phoneNumber,
          website: response.data.data[0].website
        });
        setCityState({
          label: response.data.data[0].cityName + ', ' + response.data.data[0].regionName,
          value: response.data.data[0].cityId,
        });
    });
  }
  useEffect(() => {    
    getContactDetails();
  }, []);

  const cityStateChange = (selectedOption) => {
    setCityState(selectedOption);
  };

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }
    
  //city,state data
  const customControlStyles = (base) => ({ ...base, fontSize: 14 });
  const promiseCityStateOptions = (sstring) =>
    new Promise((resolve, reject) => {
      fetch(`${API_URL + CITY_STATE_GET}?q=${sstring}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            var filtered = result.data.map((d) => ({
              value: d.id,
              label: d.name,
              type: d.type,
            }));
            resolve(filtered);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });
  
  //onchange all form element data
  function handleChange(evt) {
    const value = evt.target.value;
      setCompanyData({
          ...companyData,
          [evt.target.name]: value
      });
  }
  //

  const validateZip = (e) => {
    var zip = e.target.value;
    if (zip.length < 5 || zip.length > 5) {
      setzipError("Please enter valid zip!");
    } else if (zip.length === 5 && isNumeric(zip) === true) {
      setzipError("");
      setCompanyData({zip: zip});
    }
  };

  //form submit  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (companyData.address === "") {
      toast.error("Please fill address");
      return;
    } else if (cityState === "") {
      toast.error("Please select city, state");
      return;
    } else if (companyData.zip === "" || companyData.zip.length !== 5) {
      toast.error("Please fill valid zip code");
      return;
    } else if (companyData.phoneNumber === "" || companyData.phoneNumber.length !== 10) {
      toast.error("Please fill phone number");
      return;
    } 
    setLoading(false);
    var productUrl = API_URL + UPDATE_CONTACT_DETAILS_PROFILE;
    const formData = new FormData(document.getElementById("companyFrm"));
    formData.append("userId", local.userId);
    axios.post(productUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: local.token,
      }
    }).then((response) => {
      if (response.data.success === true) {
        // console.log(response);
        toast.success(response.data.message);
        alert(response.data.message);
        history("/profiles/"+local.userId+"/"+local.userSlugTitle);
      } else {
        toast.error(response.data.message);
        setLoading(true);
      }
    });
  };
  return (
    <>
      <Header />
      <Helmet>
          <title>{TITLE_CONTACT_DETAILS}</title>
          <meta name="description" content={MTAG_DESCRIPTION_CONTACT_DETAILS} />
          {/* <meta name="keywords" content={MTAG_KEYWORDS_CONTACT_DETAILS.join(', ')} /> */}
          <meta property="og:title" content={MTAG_TITLE_CONTACT_DETAILS} />
          <meta property="og:description" content={MTAG_DESCRIPTION_CONTACT_DETAILS} />
          <meta property="og:url" content={MTAG_URL_CONTACT_DETAILS+`editprofile/${id}/contactdetails`} />
      </Helmet>
      <div className="my-3">
        <Container>
          <Row className="align-items-center justify-content-center h-100">
            <Col xl="8" lg="8" sm="12">
              <Card>
                <Card.Body>
                  <div className="">
                    <h2 className="text-center touch m-0 py-3">{userSlugTitle === 'contactdetails'?'Edit Contact Details':''}</h2>
                    <p style={{ paddingLeft: "5px" }}>
                      <small>
                        Required fields are marked{" "}
                        <span style={{ color: "red" }}>*</span>
                      </small>
                    </p>
                    <Form className="p-3" id="companyFrm" onSubmit={handleSubmit}>                       
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" className="my-2">
                          <Form.Group className="mb-2" controlId="formGroupPassword">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="address"
                              placeholder="Address"
                              value={companyData.address}
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="6" lg="6" md="6" sm="12" className="my-2">
                          <Form.Group className="mb-2" controlId="formGroupPassword">
                            <Form.Label>
                              City, State<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <AsyncSelect
                              onChange={cityStateChange}
                              value={cityState}
                              cacheOptions
                              placeholder="City, State"
                              name="cityId"
                              defaultOptions
                              loadOptions={promiseCityStateOptions}
                              styles={{
                                control: customControlStyles,
                                option: customControlStyles,
                              }}
                            />
                          </Form.Group>
                        </Col>                        
                        <Col xl="6" lg="6" md="6" sm="12" className="my-2">
                          {" "}
                          <Form.Group>
                            <Form.Label>Zip<span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Zip"
                              maxLength={5}
                              name="zip"
                              value={companyData.zip}
                              onChange={(e) => { handleChange(e); validateZip(e); }}
                            />
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {zipError}
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" md="12" sm="12">
                          <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label>
                              Phone<span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              pattern="\d*"
                              className="form-control"
                              maxLength="10"
                              name="phoneNumber"
                              placeholder="Mobile Number"
                              value={companyData.phoneNumber}
                              onChange={(e) => handleChange(e)}                              
                            />                            
                          </Form.Group>
                        </Col>
                        <Col lg="6" md="12" sm="12">
                          <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label>
                              Website
                            </Form.Label>
                            <Form.Control
                              type="text"                              
                              className="form-control"                              
                              name="website"
                              placeholder="Website"
                              value={companyData.website}
                              onChange={(e) => handleChange(e)}
                            />                            
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="login_col text-end px-3">
                        {!loading && <div>Please wait...</div>}
                        {loading && (
                          <Button
                            variant="warning"
                            type="submit"
                            className="button_cols my-3 fw-bold text-white"
                          >
                            Save Details
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default EditProfile;
